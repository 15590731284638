import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import AutocompleteField from "../../Autocomplete/AutocompleteField";
import { postItems, updateManyItems, updateManyItemsSameValue } from "../../../api";

const FullLocation = ({ section="artworks", selectedRowIds, handleClose }) => {


    if (!selectedRowIds || selectedRowIds.length < 1) {
        return (
          <Box>
            <Typography variant="h6">Please scan and select at least one code.</Typography>
          </Box>
        );
      }
  // States
  const [formData, setFormData] = useState({
    establishments_id: null,
    shippers_id: null,
    offsite_places_id: null,
    location_status: null,
    location_responsability: null,
    location_precise_status: null,
    location_shelf: "", 
    location_details:""
  });
  const [finalOptions, setFinalOptions] = useState([]);
  const [locationStatus, setLocationStatus] = useState([]);
  const [locationResponsability, setLocationResponsability] = useState([]);
  const [filteredFinalOptions, setFilteredFinalOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  // Constants
  const foreignKey = `${section}_id`;
  const locationTable = `locations_${section}`;
  const locationKey = `${locationTable}_id`;
  const junctionTable = `locations_${section}_${section}`;
  const noHistory = true;

  // Fetch data for dropdown options
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const urls = [
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}locations_precise_status.json`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}locations_status.json`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}locations_responsability.json`,
        ];
        const [preciseStatus, status, responsability] = await Promise.all(
          urls.map((url) => fetch(url).then((res) => res.json()))
        );
        setFinalOptions(preciseStatus);
        setLocationStatus(status);
        setLocationResponsability(responsability);
        setLoading(false);
      } catch (error) {
        console.error("Fetching error:", error);
        setLoading(false);
      }
    };
    fetchAllData();
  }, []);

  // Filter precise options based on status and responsibility
  useEffect(() => {
    const filteredOptions = finalOptions.filter((option) => {
      const statusMatches = Array.isArray(option.status)
        ? option.status.includes(parseInt(formData.location_status))
        : option.status === formData.location_status;

      const responsabilityMatches = Array.isArray(option.responsability)
        ? option.responsability.includes(parseInt(formData.location_responsability))
        : option.responsability === formData.location_responsability;

      return statusMatches && responsabilityMatches;
    });

    setFilteredFinalOptions(filteredOptions);
  }, [formData.location_status, formData.location_responsability, finalOptions]);

  // Mutations
  const updateMutationJunction = useMutation({
    mutationFn: (newData) =>
      updateManyItems(junctionTable, [locationKey, foreignKey], newData),
    onSuccess: () => setShowSuccess(true),
    onError: (err) => console.error("Error updating junction table:", err),
  });

  const updateMutationTarget = useMutation({
    mutationFn: () => updateManyItemsSameValue(section, formData, selectedRowIds),
    onSuccess: () => setShowSuccess(true),
    onError: (err) => console.error("Error updating target items:", err),
  });

  const postMutation = useMutation({
    mutationFn: (data) => postItems(locationTable, data, noHistory),
    onSuccess: (data) => {
      const logEntry = JSON.parse(data.body);
      const insertID = logEntry.insertID;
      updateMutationJunction.mutate(
        selectedRowIds?.map((id) => ({
          [locationKey]: insertID,
          [foreignKey]: id,
        }))
      );
      updateMutationTarget.mutate();
    },
    onError: (error) => console.error("Error posting data:", error),
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    postMutation.mutate(formData);
  };


  const isFormValid = () => {
    if (!formData.location_status || !formData.location_responsability || !formData.location_precise_status) {
      return false; // Essential fields are missing
    }
  
    // Additional validations based on precise status
    if (formData.location_precise_status === "1" && !formData.establishments_id) {
      return false; // Establishments ID required
    }
  
    if (formData.location_precise_status === "2" && !formData.offsite_places_id) {
      return false; // Offsite places ID required
    }
  
    if (formData.location_precise_status === "6" && !formData.shippers_id) {
      return false; // Shippers ID required
    }
  
    return true; // Form is valid
  };
  

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (key === "establishments_id") {
      setFormData((prev) => ({
        ...prev,
        location_shelf: "",
        establishments_id: value,
      }));
    }
  };

  // Handle no selected rows
  if (selectedRowIds?.length < 1) {
    return (
      <Box>
        <Typography variant="h6">Please select at least one item.</Typography>
      </Box>
    );
  }

  // Loading spinner
  if (loading) {
    return (
      <Box sx={{ width: "300px", margin: "20px auto", textAlign: "center" }}>
        <CircularProgress />
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          Loading form data...
        </Typography>
      </Box>
    );
  }




  return (
    <>
      {showSuccess ? (
        <Box>
          <Typography variant="h6">Update successful!</Typography>
          <Button
            sx={{ m: 2, width: "45%" }}
            onClick={() => setShowSuccess(false)}
            variant="contained"
            color="primary"
          >
            Add another location
          </Button>
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
                  <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Selected IDs: {selectedRowIds.join(", ")}
      </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="status-label">Choose Status</InputLabel>
            <Select
              labelId="status-label"
              value={formData.location_status || ""}
              onChange={(e) => handleInputChange("location_status", e.target.value)}
            >
              {locationStatus.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="responsability-label">Choose Responsibility</InputLabel>
            <Select
              labelId="responsability-label"
              value={formData.location_responsability || ""}
              onChange={(e) =>
                handleInputChange("location_responsability", e.target.value)
              }
            >
              {locationResponsability.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="precise-status-label">Choose Precise Status</InputLabel>
            <Select
              labelId="precise-status-label"
              value={formData.location_precise_status || ""}
              onChange={(e) =>
                handleInputChange("location_precise_status", e.target.value)
              }
              disabled={filteredFinalOptions.length === 0}
            >
              {filteredFinalOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.n}
                </MenuItem>
              ))}
            </Select>
          </FormControl>


          <br />
            {formData.location_precise_status === "1" ? (
            <AutocompleteField
              title="Choose location"
              selectFile="establishments_code_short_active.json"
              selectFreesolo="false"
              disabled={!formData.location_status || formData.location_status === ""}
              conditionnalFileLoad={false}
              formData={formData}
              onValueChange={(value) =>
                handleInputChange("establishments_id", value)}
                />
            ) : formData.location_precise_status === "2" ? (
              <AutocompleteField
              title="Choose location"
              selectFile="dynamic_json/offsite_places.json"
              selectFreesolo="false"
              disabled={!formData.location_status || formData.location_status === ""}
              conditionnalFileLoad={false}
              formData={formData}
              onValueChange={(value) =>
                handleInputChange("offsite_places_id", value)}
                />
            ): formData.location_precise_status === "6" ? (
              <AutocompleteField
              title="Choose location"
              selectFile="dynamic_json/shippers.json"
              selectFreesolo="false"
              disabled={!formData.location_status || formData.location_status === ""}
              conditionnalFileLoad={false}
              formData={formData}
              onValueChange={(value) =>
                handleInputChange("shippers_id", value)}
                />
            ):null}
            <br />
            {formData.location_status === "1" && formData.location_responsability === "1" && formData.location_precise_status === "1" &&(
              <AutocompleteField
                inputRef="shelfinput"
                title="Choose Shelf"
                selectFile="logistic_codes/_REPLACE_logistic_codes.json"
                selectFreesolo="true"
                selectFileKey="n"
                value={formData.shelf || ""}
                disabled={!formData.location_status || formData.location_status === ""}
                conditionnalFileLoad="establishments_id"
                formData={formData}
                onValueChange={(value) => handleInputChange("location_shelf", value)}
              />
            )}
            <br />

          <TextField
            fullWidth
            margin="normal"
            label="Location Details"
            value={formData.location_details || ""}
            onChange={(e) => handleInputChange("location_details", e.target.value)}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isFormValid() || postMutation.isLoading}>
            {postMutation.isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </form>
      )}
    </>
  );
};

export default FullLocation;
