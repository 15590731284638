import React, { useEffect, useState, useMemo, memo, Suspense, lazy, useCallback,  } from "react";
import TableViewAsso from "../ListView/TableViewAsso";
import Loading from "../Loading/Loading";
import { Card, CardContent, Button, Box, Alert } from '@mui/material';
import { postItems,  } from "../../api";
import {
  useMutation
} from "@tanstack/react-query"; // Import your API functions

function CustomDispatch(props) {
  const [tableKey, setTableKey] = useState(0); // State for the key
  const [hideButtonMakeFinalInvoice, setHideButtonMakeFinalInvoice] = useState(false);



  const handleResults = (hasResults) => {
    setHideButtonMakeFinalInvoice(hasResults);
  };


const targetTable = "final_invoices_entities_"+props.item_data.entities_id;


const formData = {
  "sales_id" : props.item_data.id,
  "date" : props.item_data.invoice_date,
  "name" : props.item_data.name+" FI",
};

  const postMutation = useMutation({
    mutationFn: (data) => postItems(targetTable, data),
    onSuccess: (data) => {
      setTableKey(prevKey => prevKey + 1); 
       // setShowSuccess(true);
    },
    onError: (error) => {
      console.error("Error posting data:", error);
    },
  });



  const handleSubmit = (event) => {
    event.preventDefault();
   postMutation.mutate(formData);
   console.log(props.item_data);
   console.log(formData);
  };


console.log("props.queryParam",props.queryParam)


  return (
  <div>
     {props.tab.id === "sales" ? (
          <div>
            <h5>Sales</h5>
          <TableViewAsso section='sales' modelfile='sales' association="foreign_key"  queryParams={props.queryParams} fromTable={props.section}/>
          <h5>Acquisitions</h5>
           <TableViewAsso section='acquisitions' modelfile='acquisitions' association="foreign_key"  queryParams={props.queryParams} fromTable={props.section}/>

</div>
                          
    ) : props.tab.id === "final_invoices" ? (
      <div>
       {!hideButtonMakeFinalInvoice && <Box>
        <Alert severity="warning" sx={{ mb: 2 }}>
          A final invoice is definitive and can not be cancelled
        </Alert>

        <Button
          onClick={handleSubmit}
           variant="contained"
           color="secondary"
           fullWidth
         >
           Make a Final Invoice
         </Button>


        </Box>}
        <br/>  <br/>
        <TableViewAsso handleResults={handleResults} key={tableKey} section={'final_invoices_entities_'+props.item_data.entities_id} modelfile='final_invoices_entities' association="foreign_key"  queryParams={props.queryParams} fromTable={props.section}/>            

     </div> ) :(null
    )}
</div>



  );
}

export default CustomDispatch;