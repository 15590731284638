// utils/modelLoader.js

export const loadModel = async (section) => {
  let modelFileExtension = "json";
  let model = section;

  if (
    /^sets_of_/.test(section) ||
    /^narratives_/.test(section) ||
    /^narrative_/.test(section) ||
    /^locations/.test(section)
  ) {
    modelFileExtension = "js";
  } else if (/^history_/.test(section)) {
    model = "history";
  } else if (/^final_invoices_entities_/.test(section)) {
    model = "final_invoices_entities";
  }

  try {
    const importedModel = await import(`../model/${model}.${modelFileExtension}`);
    const modelData = importedModel.default;

    let filteredFields = null;
    if (modelData && modelData.fields) {
      filteredFields = modelData.fields.filter((field) => field.showInAddModal);
    }

    return { modelData, filteredFields };
  } catch (error) {
    console.error(`Error importing model for section "${model}":`, error);
    return { modelData: null, filteredFields: null };
  }
};
