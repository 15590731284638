
import React, { useState, memo, useContext } from 'react';

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import { Link } from 'react-router-dom';


import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import useAuth from "../../hooks/useAuth";
import SearchInput from './SearchInput';
import { useTheme } from '@mui/material/styles';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import StylePicker from './StylePicker';

import { useCache } from "../../contexts/CacheContext";
import { DarkMode, Subtitles, SubtitlesOff } from "@mui/icons-material"; // Save icons
import DarkModeToggle from './DarkModeToggle';

import { useNavigate } from 'react-router-dom';


const pages = [""];

const ResponsiveAppBar = memo(function ResponsiveAppBar() {
  const { userEmail, handleLogout } = useAuth(); // Add handleLogout here


  const theme = useTheme();



  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');



  const userId = localStorage.getItem("userId");


  const storedData = localStorage.getItem("StoredUserDataFromDb");

// Remove potential wrapping quotes and parse the JSON
let userData;
try {
  userData = JSON.parse(storedData); // Parse the JSON string
} catch (error) {
  console.error("Error parsing JSON:", error);
}

const employeeId = userData?.employees_id
const userName = userData?.name

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const handleLogoutClick = () => {
    handleCloseUserMenu(); // Close menu first
    handleLogout(); // Use the handleLogout from useAuth
  };





  const { noCache, toggleCache } = useCache();

  return (
    <AppBar
      position="static"
      sx={{
        zIndex: 1080,
        //backgroundColor: "#2B766C",
        backgroundColor: theme.palette.primary.main,
        height: "60px",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            zIndex: 1080,
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 300,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            CB Business Manager
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none", height: "30px" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>



          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            CBBM
          </Typography>
          
          <SearchInput navigate={navigate} />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page}
              </Button>
            ))}
          </Box>


          
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`ScanApp`}><QrCodeScannerIcon /></Link>

          <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={noCache ? "Cache Disabled, click to enable" : "Cache Enabled, click to disable"}>
            <IconButton
              onClick={toggleCache}
              sx={{
                mr: 2,
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              {noCache ? <SubtitlesOff /> : <Subtitles />}
            </IconButton>
          </Tooltip>




          <StylePicker />
          <DarkModeToggle />


            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={userName}
                  src="/static/images/avatar/2.jpg"
                  sx={{ width: "30px", height: "30px" }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                           <MenuItem key="Email">
                           <Typography textAlign="right" style={{ textDecoration: 'none', cursor: 'default' }}>Connected as {userEmail}</Typography>
              </MenuItem>
              <MenuItem key="Profile">
                           <Typography textAlign="right"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`users/detail/${userId}`}>My User Profile</Link></Typography>
              </MenuItem>
              <MenuItem key="Employee">
                           <Typography textAlign="right" ><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`employees/detail/${employeeId}`}>My Employee Profile</Link></Typography>
              </MenuItem>
        <MenuItem onClick={handleLogoutClick}> 
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
 
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default ResponsiveAppBar;