import React, { useState, useEffect } from 'react';
import { Box, InputBase, IconButton, Paper, Typography, List, ListItem, ListItemButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid2';
import { useCache } from '../../contexts/CacheContext';
import {unslugify} from '../../utils/utils';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flexGrow: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const DropdownPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  zIndex: 10,
  marginTop: theme.spacing(1),
  width: '60vw',
  maxHeight: '300px',
  overflowY: 'auto',
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
}));

const SearchInput = ({ navigate }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredResults, setFilteredResults] = useState({});
  const [data, setData] = useState(null); // State for loaded JSON data
  const { noCache } = useCache(); // Get the cache state

  const categories = data ? Object.keys(data) : [];
  const cacheBuster = noCache ? `?${Date.now()}` : '';

  // Fetch the JSON file from S3 when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/global_search.json${cacheBuster}`;
        const response = await fetch(fetchUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch search data');
        }
        const jsonData = await response.json();
        setData(jsonData); // Set loaded JSON data
      } catch (error) {
        console.error('Error loading JSON data:', error);
      }
    };

    fetchData();
  }, []);


  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setShowSuggestions(term.trim() !== '');
  
    if (term.trim() && data) {
      const results = {};
      categories.forEach((category) => {
        results[category] = data[category].filter((item) =>
          (item?.n?.toLowerCase().includes(term.toLowerCase()) || 
           item?.i?.toString().toLowerCase().includes(term.toLowerCase()))
        );
      });
      setFilteredResults(results);
    } else {
      setFilteredResults({});
    }
  };

  
  

  // Handle direct search submission
  const handleSearchSubmit = () => {
    navigate(`/artworks/table?search=${encodeURIComponent(searchTerm)}`);
    setShowSuggestions(false);
  };

  // Handle "Search in [category]" click
  const handleSuggestionClick = (category) => {
    navigate(`/${category}/table?search=${encodeURIComponent(searchTerm)}`);
    setShowSuggestions(false);
  };


  const handleItemClick = (category, id) => {
    navigate(`/${category}/detail/${id}`);
    setShowSuggestions(false);
  };


  if (!data) {
    return <Typography>Loading search data...</Typography>; // Show a loading message while data is being fetched
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {/* Search Input */}
      <Search>
        <StyledInputBase
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          inputProps={{
            'aria-label': 'search',
          }}
        />
        <IconButton onClick={handleSearchSubmit} sx={{ color: 'white' }}>
          <SearchIcon />
        </IconButton>
      </Search>

      {/* Dropdown Suggestions */}
      {showSuggestions && (
        <DropdownPaper>
          <Grid container spacing={2}>
            {categories.map((category) => (
              <Grid item sx={{ xs: 12, md: 6, minWidth:"40%", display: 'block'}}  key={category}>
                <Box>
                  {/* Category Header */}
                  <Typography variant="subtitle1" sx={{ padding: 1, fontWeight: 'bold' }}>
                    {unslugify(category)}
                  </Typography>

                  {/* "Search in [Category]" Link */}
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleSuggestionClick(category)}>
                      <Typography variant="body2">Search "{searchTerm}" in {category}</Typography>
                    </ListItemButton>
                  </ListItem>

                  {/* Filtered Results */}
                  <List>
                    {filteredResults[category] && filteredResults[category].length > 0 ? (
                      filteredResults[category].slice(0, 5).map((item) => (
                        <ListItem key={item.i} disablePadding>
                          <ListItemButton onClick={() => handleItemClick(category, item.i)}>
                            {category === 'artworks' && item.m!=null && (
                              <img
                               src={`https://cb-business-manager.s3.eu-west-3.amazonaws.com/artworks/${item.i}/main_image/${item.m}-132-80.jpg`}
                                alt={item.n}
                                style={{ width: '50px', height: '50px', marginRight: '10px' }}
                              />
                            )}
                            <Typography variant="body2">{item.n} ({item.i})</Typography>
                          </ListItemButton>
                        </ListItem>
                      ))
                    ) : (
                      <ListItem disablePadding>
                        <Typography variant="body2" sx={{ padding: 1, color: 'gray' }}>
                          No matches in {category}.
                        </Typography>
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Grid>
            ))}
          </Grid>
        </DropdownPaper>
      )}
    </Box>
  );
};

export default SearchInput;
