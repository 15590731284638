import React, { useState, useEffect, useId } from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button
} from "@mui/material";
import { Link } from 'react-router-dom';
import { useCache } from '../../contexts/CacheContext';
import { unslugify } from "../../utils/utils";
import LinkIcon from '@mui/icons-material/Link';

const RecentHistory = () => {
  const [data, setData] = useState(null); // State for loaded JSON data
  const [userData, setUserData] = useState(null); // State for user data
  const [showAll, setShowAll] = useState(false); // State for toggling "View More"
  const { noCache } = useCache(); // Get the cache state

  const cacheBuster = noCache ? `?${Date.now()}` : '';

  // Fetch the initial 50 records
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/recent_history_last_50.json${cacheBuster}`;
        const response = await fetch(fetchUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch search data');
        }
        const jsonData = await response.json();
        setData(jsonData); // Set loaded JSON data
      } catch (error) {
        console.error('Error loading JSON data:', error);
      }
    };

    fetchData();
  }, []);

  // Fetch the full 300 records when "View More" is clicked
  const fetchFullData = async () => {
    try {
      const fetchUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/recent_history_all.json${cacheBuster}`;
      const response = await fetch(fetchUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch full history data');
      }
      const jsonData = await response.json();
      setData(jsonData); // Replace the data with full JSON
      setShowAll(true); // Update state to reflect "View More" has been clicked
    } catch (error) {
      console.error('Error loading full history data:', error);
    }
  };

  // Fetch the user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const fetchUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/users.json${cacheBuster}`;
        const response = await fetch(fetchUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const jsonData = await response.json();
        setUserData(jsonData); // Set loaded user data
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const getUserName = (userId) => {
    if (!userData) return userId; // Return ID if userData not loaded yet
    const user = userData.find(user => user.id === userId);
    return user ? user.name : userId; // Return name if found, otherwise return ID
  };

  const eventData = { 1: "update", 2: "associate", 3: "detach", 4: "long text", 5: "create" };
  const getEventName = (event) => {
    const eventName = eventData[event];
    return eventName || event; // Return event name if found, otherwise return event
  };

  const truncateString = (str) => {
    return str?.length > 20 ? str.slice(0, 15) + "..." : str;
  };

  const uniqueIdPrefix = useId();
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Recent History</Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table size="small">
            <TableHead>
            <TableRow key="headHistory">
                <TableCell>Section</TableCell>
                <TableCell align="right">User</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Id</TableCell>
                <TableCell align="right">Event Type</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Old Value</TableCell>
                <TableCell align="right">New Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <TableRow key={item.ta+item.id+item.nv}>
                  <TableCell align="right">{unslugify(item.ta)}</TableCell>
                  <TableCell align="right">
                    {getUserName(item.fmb)}
                    <Link to={`/users/detail/${item.fmb}`}><LinkIcon /></Link>
                  </TableCell>
                  <TableCell align="right">{item.ca}</TableCell>
                  <TableCell>
                    <Link to={`/${item.ta}/detail/${item.id}`}>{item.id}</Link>
                  </TableCell>
                  <TableCell>{getEventName(item.et)}</TableCell>
                  <TableCell>
                    <Link to={`/${item.ta}/detail/${item.id}`}>{item.n}</Link>
                  </TableCell>
                  <TableCell align="right">{truncateString(item.ov)}</TableCell>
                  <TableCell align="right">{truncateString(item.nv)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!showAll && (
          <Button
            variant="contained"
            color="primary"
            onClick={fetchFullData}
            sx={{ marginTop: 2 }}
          >
            View More
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default RecentHistory;
