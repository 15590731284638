export const checkPermissionsDetail = (permissions, modelData, section, itemData, userId, employeeId) => {
    // Default values: permissions are granted unless specifically restricted
    let canModify = true;
    let canViewDetail = true;
    let canViewGlobal = true;

    console.log("permissiosn from utils", permissions)

    // Master Pass overrides all
    if (modelData?.permissions?.allow_master_pass && permissions.includes('master_pass_' + section)) {
        return { canModify: true, canViewDetail: true, canViewGlobal: true };
    }

    // If specific view or modify permissions are defined, restrict access
    if (modelData?.permissions?.permissions_to_view || modelData?.permissions?.permissions_to_view_detail) {
        canViewDetail = permissions.includes(`can_view_detail_${section}`);
    }
    else{
        canViewDetail = permissions.includes(`can_view_${section}`);
    }

    if (modelData?.permissions?.permissions_to_modify) {
        canModify = permissions.includes(`can_modify_${section}`);
    }

    if (modelData?.permissions?.permissions_to_view_global) {
        canViewGlobal = permissions.includes(modelData?.permissions?.permissions_to_view_global);
    }

    if (modelData?.permissions?.permissions_to_edit_limited_to_some_id) {
        if (section === "users") {
            canModify = userId == itemData.users_id || userId == itemData.id;
        } 
    }
        // ID-based restrictions (e.g., users or employees sections)
    if (modelData?.permissions?.permissions_to_view_limited_to_some_id) {
            if (section === "employees") {
                canModify = itemData.id == employeeId || userId == itemData.users_id;
                if (!canModify) {
                    return { canModify: false, canViewDetail: false, canViewGlobal: false, message_permission: "you don't have the permission to manage this employee" }; // Return early if restricted
                }
            }
        }

    return { canModify, canViewDetail, canViewGlobal };
};
