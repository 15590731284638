import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Drawer,
  Switch,
  FormGroup,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Divider,
  Alert,
  Box
} from "@mui/material";
import "./OffCanvas.css";
import FieldHistory from "../History/FieldHistory";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import * as Constants from '../../constants/selectConstants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AutocompleteWithQuery from "../Autocomplete/AutocompleteWithQuery";
import AutocompleteField from "../Autocomplete/AutocompleteField";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import "dayjs/locale/fr"; // Import the French locale
import utc from 'dayjs/plugin/utc'; // Import UTC plugin if needed for UTC manipulations
import timezone from 'dayjs/plugin/timezone';
import { useCache } from "../../contexts/CacheContext"; 



dayjs.extend(utc); // Use the UTC plugin
dayjs.extend(timezone);

function EditOffCanvas({
  show,
  handleClose,
  field,
  value,
  handleUpdate,
  placement,
  id,
  section,
  conditionnalFileLoad
}) {


  const { noCache } = useCache(); // Get the cache state
  
  const [fieldValue, setFieldValue] = useState(value);
  const [timeValue, setTimeValue] =  useState(
    value ? dayjs.utc(value) : dayjs.utc()
  );
  const [dateTimeValue, setDateTimeValue] = useState(
    value ? dayjs.utc(value) : dayjs.utc()
  );
  const handleInputDateChange = (value) => {
    if (value && value !='0000-00-00') {
          // Format the Day.js object to 'YYYY-MM-DD'
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          console.log("Formatted date:", formattedDate);
          setFieldValue(formattedDate);
      };
  }

  const handleInputTimeChange = (newValue) => {
    if (newValue) {
      setTimeValue(newValue);
      const utcTime = dayjs.utc(newValue).format('HH:mm:ss');
      console.log("Formatted UTC time:", utcTime);
      setFieldValue(utcTime);
    };
  }
  

  const handleInputDateTimeChange = (value) => {
    if (value && value !='0000-00-00 00:00:00') {
      // Convert the Day.js object to UTC
      setDateTimeValue(value);
      const utcDateTime = dayjs.utc(value).format('YYYY-MM-DD HH:mm:ss');
      console.log("Formatted UTC date and time:", utcDateTime);
      setFieldValue(utcDateTime);
    };
  }
  





  useEffect(() => {
console.log('fieldValue', fieldValue)
  }, [fieldValue]);



  const [selectOptions, setSelectOptions] = useState([]);




  useEffect(() => {
    const cacheBuster = noCache ? `?${Date.now()}` : '';
    if (field.fieldType === "select" || field.fieldType === "autocomplete") {
      if (field.selectLocalValue) {
        setSelectOptions(Constants[field.selectLocalValue]);
      } else if (field.selectFile) {
        const fetchData = async () => {
          const response = await fetch(
            `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${field.selectFile}${cacheBuster}`
          );
          console.log('filefteched', `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${field.selectFile}${cacheBuster}`)
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setSelectOptions([{ id: 'SetNull', name: 'None' }, ...data]);
        };
        fetchData().catch((error) => console.error("Fetching error:", error));
      }
    }
  }, [field, Constants, noCache]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleUpdate(field, fieldValue);
    handleClose();
  };

  const handleSwitchChange = (event) => {
    // Convert boolean to 1 or 0
    setFieldValue(event.target.checked ? 1 : 0);
  };

  const anchor = placement === "end" ? "right" : placement;

  // Render Field based on fieldType
  const renderField = () => {
    switch (field.fieldType) {
      case "input":
        default:
          return (
            <TextField
              fullWidth
              margin="normal"
              label={field.title}
              variant="outlined"
              type="text"
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              required={field.required}
            />
          );

      case "select":
        return (
          <FormControl fullWidth margin="normal">
            <InputLabel>{field.title}</InputLabel>
            <Select
              label={field.title}
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              required={field.required}
            >
              {Array.isArray(selectOptions) && selectOptions.map((option) => (
    <MenuItem key={option.id} value={option.id}>
        {option.n ? option.n : option.name}
    </MenuItem>
))}
            </Select>
          </FormControl>
        );

        case "date":
          return (
            <div>
              <DatePicker
              format="DD/MM/YYYY"
                label={field.title}
                value={dayjs(fieldValue)}
                onChange={(newValue) => handleInputDateChange(newValue)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
              />
              <br />
            </div>
          );

        case "switch":
          return (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={fieldValue === 1}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={field.title}
                />
              </FormGroup>
            </div>
          );

          case "autocomplete":
            // Assuming fieldValue is an ID, find the corresponding object from selectOptions
            const autoCompleteValue = selectOptions.find(option => option.id === fieldValue);
          
            return (
              <Autocomplete
                value={autoCompleteValue || null} // Set the found object as the value
                onChange={(event, newValue) => {
                  setFieldValue(newValue ? newValue.id : null);
                }}
                options={selectOptions}
                getOptionLabel={(option) => option.n || option.name}
                renderInput={(params) => (
                  <TextField {...params} label={field.title} variant="outlined" fullWidth margin="normal" />
                )}
              />
            );


            case "autocompleteFreeSolo":
              return (
                <AutocompleteField
                  value={fieldValue} 
                  title={field.title}
                  selectFile={field.selectFile}
                  selectFileKey={field.selectFileKey}
                  defaultLoadValue={conditionnalFileLoad+'_'}
                  formData={[]}
                  selectFreesolo={true}
                  sendBackJson={true}
                  onValueChange={(newValue) => setFieldValue(newValue ? newValue.n : null)}
                />
              );

            case "autocompleteWithQuery":
              return (
                <AutocompleteWithQuery
                value={fieldValue} 
                setNone={true}
                formData={[]}
                selectFileKey="id"
                freeSolo={field.selectFreesolo}
                selectFile={field.selectFile}
                title={`Select ${field.selectFile}`}
                sendBackJson={true}
                onValueChange={(newValue) => setFieldValue(newValue ? newValue.id : null)}
              />
              );

          case "text":
            if(!field.noCode){
          return (
            <div>
              <ReactQuill label={field.title} theme="snow" value={fieldValue}  onChange={(newValue) => setFieldValue(newValue)}/>
            </div>
          );
        }
        else {
            return (
              <div>
              <TextField
                        fullWidth
                        multiline
                        margin="normal"
                        label={field.title}
                        variant="outlined"
                        type="text"
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                        required={field.required}
                      />   {field.maxChar && (<p style={{color: fieldValue?.length > field.maxChar && 'red' || 'green'}}>Character count: {(field.maxChar - fieldValue?.length) || field.maxChar}</p>)} </div>
            );

            

          }
          case "time":
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div>
      
                <TimePicker
                sx={{width: '100%'}} 
  value={timeValue || dayjs(fieldValue).tz("America/New_York")}
  onChange={(newValue) => handleInputTimeChange(newValue)}
  timezone="America/New_York"
  label={field.title+ ' (New_York Time)'}
  renderInput={(params) => (
    <TextField {...params} sx={{width: '100%'}}  margin="normal" />
  )}
/>
<br/><br/><br/>
<TimePicker
sx={{width: '100%'}} 
  value={timeValue}
  onChange={(newValue) => handleInputTimeChange(newValue)}
  timezone="Europe/Paris"
  ampm={false}
  label={field.title+ ' (Paris Time)'}
/>
<br/><br/><br/>
<TimePicker
sx={{width: '100%'}} 
  value={timeValue}
  onChange={(newValue) => handleInputTimeChange(newValue)}
  timezone="Asia/Tokyo"
  ampm={false}
  label={field.title+ ' (Tokyo Time)'}
/>

               
                <br />
              </div></LocalizationProvider>
            );
            case "datetime":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
  <div className="w-100">
    <DateTimePicker
    sx={{width: '100%'}} 
      label={field.title + ' (New York Time)'}
      value={dateTimeValue}
      timezone="America/New_York"
      onChange={(newValue) => handleInputDateTimeChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} sx={{width: '100%'}}  margin="normal" />
      )}
    />
 <br/><br/><br/>
    <DateTimePicker
    sx={{width: '100%'}} 
    style={{width: "100%"}}
      ampm={false}
      label={field.title + ' (Paris Time)'}
      value={dateTimeValue}
      timezone="Europe/Paris"
      onChange={(newValue) => handleInputDateTimeChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} sx={{width: '100%'}}  margin="normal" />
      )}
    />
 <br/><br/><br/>
    <DateTimePicker
    sx={{width: '100%'}} 
      ampm={false}
      value={dateTimeValue}
      label={field.title + ' (Tokyo Time)'}
      timezone="Asia/Tokyo"
      onChange={(newValue) => handleInputDateTimeChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} sx={{width: '100%'}}  margin="normal" />
      )}
    />
    <br />
  </div>
</LocalizationProvider>
        );
    }
  };

  return (
    <Drawer
      anchor={anchor}
      open={show}
      onClose={handleClose}
      variant="temporary"
      PaperProps={{
        style: {
          width: "50%",
        },
      }}
    >
  

      <div style={{ padding: "16px" }}>
        <form onSubmit={onSubmit}>
          {renderField()}
          {field.helpText && (<Alert severity="info"> {field?.helpText}</Alert>)}
          <Button
            variant="contained"
            color="primary"
            type="submit"

            style={{width:'100%', marginTop: "16px" }}
          >
            Update
            </Button>
        </form>
        <Divider sx={{marginTop: 3, marginBottom: 1}}/>

        <FieldHistory section={section} handleClose={handleClose} field={field} id={id} /> 

      </div>
    </Drawer>
  );
}

export default EditOffCanvas;
