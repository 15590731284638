import React from "react";
import { Typography, Box } from "@mui/material";
import ModalButtonsAddTo from "../../Modal/ModalButtonsAddTo";

const MoreOptions = ({section="artworks", selectedRowIds, okMessage}) => {


    const names = ["sets_to", "sets","narratives", "locations_artworks", "exhibitions","reservations","fairs","dimensions_artworks","incoming_consignments","outgoing_consignments", "artpartners_to_fav", "artpartners_to_collection","publications","extra_costs","keywords","keywords_to"];


    if (!selectedRowIds || selectedRowIds.length < 1) {
        return (
          <Box>
            <Typography variant="h6">Please scan and select at least one code.</Typography>
          </Box>
        );
      }

      const handleRefresh = () => {
        okMessage("OK");
      };

  return (
    <div>
              <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Selected IDs: {selectedRowIds.join(", ")}
      </Typography>
   <ModalButtonsAddTo buttonNames={names} section={section} selectedRowIds={selectedRowIds} handleRefresh={handleRefresh} />

    </div>
  );
};

export default MoreOptions;