import JsBarcode from 'jsbarcode';
import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { unslugify, getDisplayValueWithFullModelData } from "../../../utils/utils";
import Grid from '@mui/material/Grid2';


/**
 * Generates a barcode image URL for a given ID.
 */
export const generateBarcode = (id, entriesPerPage) => {
  if (!id) {
    console.error('Invalid ID passed to generateBarcode:', id);
    return null;
  }

  try {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, id.toString().padStart(6, '0'), {
      format: "CODE128",
      width: 4,
      height: entriesPerPage === "table" ? 120 : 40,
      fontSize: 8,
      displayValue: false,
    });
    return canvas.toDataURL("image/png");
  } catch (error) {
    console.error('Error generating barcode:', error);
    return null;
  }
};

/**
 * Extracts PDF-related field keys from the model data.
 */
export const getPdfOptionsFieldKeys = (modelData) => {
  if (!modelData?.fields) return [];
  return modelData.fields
    .filter((field) => field.pdfOptions) // Filter fields with pdfOptions=true
    .map((field) => field.key);         // Extract only the keys
};


/**
 * Calculates totals for fields that are marked with `pdfOptionsTotal`.
 */
export const calculateTotals = (data, modelData) => {
  const totals = {};
  modelData?.fields
    .filter((field) => field.pdfOptionsTotal)
    .forEach((field) => {
      totals[field.key] = data.reduce((sum, item) => {
        const value = parseFloat(item[field.key]);
        return sum + (isNaN(value) ? 0 : value);
      }, 0);
    });
  return totals;
};

/**
 * Renders the list of checkboxes for PDF options.
 */
export const renderPdfOptionsCheckboxes = (
  modelData,
  checkboxValues,
  handleCheckboxChange
) => {
  return (
    <Grid container spacing={2}>
      {modelData?.fields
        ?.filter((field) => field.pdfOptions)
        .map((field) => (
          <Grid item size={{ xs: 12, sm: 6, md: 4 }} key={field.key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxValues[field.key] || false}
                  onChange={handleCheckboxChange}
                  name={field.key}
                />
              }
              label={unslugify(field.title)}
            />
          </Grid>
        ))}
    </Grid>
  );
};

/**
 * Dynamically renders selected fields for a given data item.
 */
export const renderCheckedFields = (item, modelData, checkedFields, selectMappings) => {
  return modelData?.fields
    .filter((field) => checkedFields[field.key])
    .map((field) => {
      const displayValue = getDisplayValueWithFullModelData(modelData, field.key, item[field.key], selectMappings);

      return (
        <Text key={field.key} style={{ fontSize: 8 }}>
          {unslugify(field.title)}: {displayValue || 'N/A'}
        </Text>
      );
    });
};

/**
 * Formats a date for PDF footers or headers.
 */
export const formatDateForPdf = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

/**
 * Groups data into pages for a PDF, calculating the total number of pages.
 */
export const groupDataIntoPages = (data, entriesPerPage=10) => {
  const totalPages = Math.ceil(data.length / entriesPerPage);
  const paginatedData = chunkData(data, entriesPerPage);
  return { totalPages, paginatedData };
};


export const chunkData = (data, size=10) => {
  const result = [];
  for (let i = 0; i < data.length; i += size) {
    result.push(data.slice(i, i + size));
  }
  return result;
};



