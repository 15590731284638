import React, { useState, useEffect } from "react";
import { Typography, Box, Alert } from "@mui/material";
import { useCache } from "../../../contexts/CacheContext";
import { useMutation } from "@tanstack/react-query";
import { postItems, updateManyItems, updateManyItemsSameValue } from "../../../api";

const QuickLocation = ({section="artworks", selectedRowIds}) => {
  const [establishments, setEstablishments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState(null); // To display the selected message
  const { noCache } = useCache(); // Get the cache state
  const cacheBuster = noCache ? `?${Date.now()}` : "";
  const [formData, setFormData] = useState({
    establishments_id: null,
    shippers_id: null,
    offsite_places_id: null,
    location_status: 1,
    location_responsability: 1,
    location_precise_status: 1,
    location_shelf:"", 
    location_details:""
  });

  // Constants
  const foreignKey = `${section}_id`;
  const locationTable = `locations_${section}`;
  const locationKey = `${locationTable}_id`;
  const junctionTable = `locations_${section}_${section}`;
  const noHistory = true;

  const colorPalette = {
    11: "brown", 
    12: "green", 
    16: "hotpink", 
    15: "teal", 
    9: "deepskyblue", 
    14: "#f2e22c", 
    4: "coral", 
    8: "midnightblue", 
    9: "orangered", 
    7: "rebeccapurple"  
  };
  /*
{id: 11, name: "Lyon Longue", display_name: "Lyon", code: "LYO-LON-011", address: "21 rue Longu", …}
1
{id: 16, name: "Tokyo Ginza", display_name: "Tokyo", code: "TOK-GIN-016", address: "5 Chome-12-6 Ginza, Chuo City", …}
2
{id: 12, name: "Saint-Étienne Acéries", display_name: "Saint-Étienne", code: "STE-ACE-012", address: "10 rue des Aciéries", …}
3
{id: 15, name: "La Chaulme", display_name: "Résidence La Chaulme", code: "CHA-REY-015", address: "1 chemin du Grand Rey", …}
4
{id: 9, name: "Genève Neuchâtel", display_name: "Geneva", code: "GEN-NEU-009", address: "8 rue de Neuchâtel", …}
5
{id: 7, name: "Wandhaff", display_name: "Wandhaff", code: "KOE-WAN-007", address: "13 - 15 rue d'Arlon", …}
6
{id: 4, name: "Paris Renard", display_name: "Paris", code: "PAR-REN-004", address: "23 rue du Renard ", …}
7
{id: 8, name: "New York Madison", display_name: "New York", code: "NYC-MAD-008", address: "956 Madison Avenue", …}
8
{id: 14, name: "Panéry", display_name: "Panéry", code: "POU-PAN-014", address: "Domaine de Panéry", …}


  */
  

  useEffect(() => {
    const fetchEstablishments = async () => {
      try {
        const s3Url = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}`;
        const response = await fetch(`${s3Url}dynamic_json/establishments.json${cacheBuster}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // Filter only active establishments
        const activeEstablishments = data.filter(
          (item) => item.is_active === 1 || item.is_active === "1"
        );

        setEstablishments(activeEstablishments);
        console.log(activeEstablishments)
      } catch (error) {
        console.error("Failed to fetch establishments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEstablishments();
  }, [noCache]);



  const updateMutationJunction = useMutation({
    mutationFn: (newData) =>
      updateManyItems(junctionTable, [locationKey, foreignKey], newData),
    onError: (err) => console.error("Error updating junction table:", err),
  });

  const updateMutationTarget = useMutation({
    mutationFn: () => updateManyItemsSameValue(section, formData, selectedRowIds),
    onSuccess: () => setShowSuccess(true),
    onError: (err) => console.error("Error updating target items:", err),
  });

  const postMutation = useMutation({
    mutationFn: (data) => postItems(locationTable, data, noHistory),
    onSuccess: (data) => {
      const logEntry = JSON.parse(data.body);
      const insertID = logEntry.insertID;
      updateMutationJunction.mutate(
        selectedRowIds?.map((id) => ({
          [locationKey]: insertID,
          [foreignKey]: id,
        }))
      );
      updateMutationTarget.mutate();
    },
    onError: (error) => console.error("Error posting data:", error),
  });



  const handleButtonClick = (code) => {
    const establishmentsId = code.slice(-3); // Extract last 3 digits
    setMessage(`Location set to our responsibility and our premises and establishments_id: ${establishmentsId}`);
    setFormData({
      establishments_id: establishmentsId,
      shippers_id: null,
      offsite_places_id: null,
      location_status: 1,
      location_responsability: 1,
      location_precise_status: 1,
      location_shelf:"", 
      location_details:""
    });
    postMutation.mutate({
      establishments_id: establishmentsId,
      shippers_id: null,
      offsite_places_id: null,
      location_status: 1,
      location_responsability: 1,
      location_precise_status: 1,
      location_shelf:"", 
      location_details:""
    });
  };

  const getColorForId = (id) => {
    return colorPalette[id]; // Cycle through colors
  };
  

  if (!selectedRowIds || selectedRowIds.length < 1) {
    return (
      <Box>
        <Typography variant="h6">Please scan and select at least one code.</Typography>
      </Box>
    );
  }


  if (loading) {
    return (
      <Typography variant="body2" color="textSecondary">
        Loading establishments...
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Quick Location
      </Typography>

      <Typography variant="body2" sx={{ marginBottom: 2 }}>
        Selected IDs: {selectedRowIds.join(", ")}
      </Typography>

      {message && showSuccess && (
        <Alert
          severity="info"
          onClose={() => setMessage(null)} // Clear message on close
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      )}

      {establishments.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
            gap: 2,
            width: "100%",
          }}
        >
          {establishments.map((establishment) => (
            <Box
              key={establishment.id}
              onClick={() => handleButtonClick(establishment.code || "No Code")}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: getColorForId(establishment.id),
                color: "#fff",
                fontWeight: "bold",
                height: 100,
                width: "100%",
                cursor: "pointer",
                textAlign: "center",
                borderRadius: 1,
                boxShadow: 2,
                userSelect: "none",
                "&:hover": {
                  opacity: 0.9,
                },
              }}
            >
              {establishment.name}
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No active establishments found.
        </Typography>
      )}
    </Box>
  );
};

export default QuickLocation;
