import axios from "axios";

/*
const tokenLocal = localStorage.getItem('CognitoJWTToken');
const userId = localStorage.getItem('userId');
const userEmail = localStorage.getItem('userEmail');

const tokenToSend = tokenLocal;
*/

const api = axios.create({
  baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});


let tokenToSend;
let userId = localStorage.getItem('userId');


api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('CognitoJWTToken');
    tokenToSend = token;
    userId = localStorage.getItem('userId');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


console.log('use query api has been called');
function objectToQueryString(obj) {
  return Object.entries(obj)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
}


export function loadItems(section, queryParams="", fieldsToFetch="", fieldsToSearch="",) {
  
  console.log('use query loadItems api has been called', queryParams);

  let queryString ="";
  if(queryParams){
  queryString = '&'+objectToQueryString(queryParams);
  }

  let fieldsToFetchQUery ="";
  if(fieldsToFetch){
      fieldsToFetchQUery = `&fields=${fieldsToFetch}`;
  }


  let fieldsToSearchQuery ="";
  if(fieldsToSearch){
    fieldsToSearchQuery = `&fields_to_search=${fieldsToSearch}`;
  }


 return api.get(`?table=${section}${queryString}${fieldsToFetchQUery}${fieldsToSearchQuery}`)
    .then((response) => {
      console.log('api get has been called', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching items:", error);  // Log any errors
      throw error;  // Re-throw the error to ensure it's handled elsewhere
    });
}


export function loadItem(section, id, queryParams="") {

  let queryString ="";
  if(queryParams){
  queryString = '&'+objectToQueryString(queryParams);
  }

 return api.get(`?table=${section}&id=${id}${queryString}`)
    .then((response) => {
      console.log('api get 1 item called', response.data[0]); 
      return response.data[0];
    })
    .catch((error) => {
      console.error("Error fetching items:", error);  // Log any errors
      throw error;  // Re-throw the error to ensure it's handled elsewhere
    });
}


export function saveItemJson(section, id=null, queryParams="") {

  let queryString ="";
  if(queryParams){
  queryString = '&'+objectToQueryString(queryParams);
  }
  let idString ="";
  if(id){
    idString = `&id=${id}`;
    }
 return api.get(`?table=${section}&id=${idString}${queryString}`)
    .then((response) => {
      console.log('api get saveItemJson'); 
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching items:", error);  // Log any errors
      throw error;  // Re-throw the error to ensure it's handled elsewhere
    });
}

/*

&save_as_dynamic_json=true&single_file=true
const query = useQuery({ 
  queryKey: ['items', tableToGet, {save_as_dynamic_json: true}, fields], 
  queryFn: () => loadItems(tableToGet, {save_as_dynamic_json: true, json_name_posted: name, limit:40000}, fields),
  enabled: !!goodToGo
});*/


export function loadItemVariousCritera(section, queryParams) {

  const queryString = objectToQueryString(queryParams);


  console.log('loadItemVariousCritera', `?table=${section}&${queryString}`);


 return api.get(`?table=${section}&${queryString}`)
     .then((response) => {
       console.log('api loadItemVariousCritera item has been called', response.data[0]);
       return response.data[0];
     })
     .catch((error) => {
       console.error("Error fetching items:", error);  // Log any errors
       throw error;  // Re-throw the error to ensure it's handled elsewhere
     });
 }


export function postItems(section, dataObj = {}, noHistory="false", noCreatedBy="false") {
  const url = '';  // Add the appropriate endpoint if needed.

  // Constructing the request payload
  const requestBody = {
    httpMethod: "POST",
    body: JSON.stringify({
      table: section,
      no_history_trigger: noHistory,
      no_created_by: noCreatedBy,
      user: userId,
      data: dataObj
    })
  };

  console.log('api post item has been called', section, requestBody);
  console.log('requestBody', requestBody.body);
  // Sending the POST request
  return api.post(url, requestBody)
    .then((response) => {
      console.log('api post has been called', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error posting items:", error);
      throw error;
    });
}


export function updateItem(section, id, field, newValue) {
  const url = '';  // Add the appropriate endpoint if needed.
  console.log('put called', section, id, field, newValue);
  const requestBody = {
    httpMethod: "PUT",
    body: JSON.stringify({
      table: section,
      id: id,
      field: field,
      value: newValue,
      user: userId
    })
  };

  // Sending the POST request
  return api.put(url, requestBody)
    .then((response) => {
      console.log('api put has been called', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error posting items:", error);
      throw error;
    });
}


export function updateManyItems(section, fields, values, position=false) {
  const url = '';  // Add the appropriate endpoint if needed.
  console.log('updateManyItems called', section, fields, values, position);
  
  const requestBody = {
    httpMethod: "PUT",
    body: JSON.stringify({
      position: position,
      bulk: true,
      table: section,
      key_fields: fields,
      records: values,
      user: userId
    })
  };
  console.log('api updateManyItems has been called');
  console.log('upmanybody',requestBody.body);
  // Sending the POST request
  return api.put(url, requestBody)
    .then((response) => {
      console.log('api updateManyItems : ', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error posting items:", error);
      throw error;
    });
}



export function updateManyItemsSameValue(section, values, ids, bulk_same=true) {
  const url = '';  // Add the appropriate endpoint if needed.
  console.log('updateManyItemsSameValue called', section, values, ids, userId);

  const requestBody = {
    httpMethod: "PUT",
    body: JSON.stringify({
      bulk_same: bulk_same,
      table: section,
      records: values,
      ids: ids,
      user: userId
    })
  };
  console.log('samevaluebody',requestBody.body);
  // Sending the POST request
  return api.put(url, requestBody)
    .then((response) => {
      console.log('api updateManyItems has been called', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error posting items:", error);
      throw error;
    });
}



export function insertManyHistorySameValue(section, values, ids) {
  const url = '';  // Add the appropriate endpoint if needed.
  console.log('insertManyHistorySameValue called', section, values, ids, userId);

  const requestBody = {
    httpMethod: "PUT",
    body: JSON.stringify({
      insert_many_history: true,
      table: section,
      records: values,
      ids: ids,
      user: userId
    })
  };
  console.log('insertManyHistorySameValueBody',requestBody.body);
  // Sending the POST request
  return api.put(url, requestBody)
    .then((response) => {
      console.log('api insertManyHistorySameValue has been called', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error posting items:", error);
      throw error;
    });
}




export function deleteItem(section, keyfields) {
  const url = '';  // Add the appropriate endpoint if needed.
  console.log('delete called', section, keyfields);
  const requestBody = {
    httpMethod: "DELETE",
    body: JSON.stringify({
      table: section,
      fields: keyfields
    })
  };

  // Sending the POST request
  return api.put(url, requestBody)
    .then((response) => {
      console.log('api delete has been called', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error deleting items:", error);
      throw error;
    });
}