/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cognito_identity_pool_id": "eu-west-3:e218f324-50bd-4239-b2cd-eca8dfa83968",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_J17o2TFez",
    "aws_user_pools_web_client_id": "6ug04ebhljqprqcqui6fctmhs0",
    "oauth": {
        "domain": "auth-cb-cbagoogle.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
    "redirectSignIn": "http://localhost:3000/,https://www.cbapp.net/",
    "redirectSignOut": "http://localhost:3000/logout/,https://www.cbapp.net/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
