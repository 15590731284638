import React, { useState } from "react";
import { useMutation,useQuery } from "@tanstack/react-query";
import { Button } from "@mui/material";
import "./GameModal.css"; // Optional CSS for styling the modal.
import { loadItems , updateManyItems} from "../../api";

const ArtworksColorModals = ({ onClose, config = { artworksPerGame: 3 } }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Tracks the current artwork
  const [selectedColors, setSelectedColors] = useState([]); // Tracks selected colors
  const [gameFinished, setGameFinished] = useState(false); // Tracks if the game is finished

  // Fetch artworks
  const { data: artworks = [], isLoading, isError, refetch } = useQuery({
    queryKey: ["artworks"],
    queryFn: () => loadItems("artworks", { limit: config.artworksPerGame, custom_specific: "get_table_with_no_mtm", target_table:"keywords"}, "id,name,main_image", ""), // Fetch enough artworks to handle multiple rounds
    retry: false,
    enabled: true,
  });

  const fetchedColors = [
    { id: 1, name: "Navy" },
    { id: 2, name: "Blue" },
    { id: 3, name: "LightSkyBlue" },
    { id: 4, name: "DarkRed" },
    { id: 5, name: "Red" },
    { id: 6, name: "Pink" },
    { id: 7, name: "DarkGreen" },
    { id: 8, name: "Green" },
    { id: 9, name: "PaleGreen" },
    { id: 10, name: "Brown" },
    { id: 11, name: "Orange" },
    { id: 12, name: "Yellow" },
    { id: 13, name: "Indigo" },
    { id: 14, name: "Purple" },
    { id: 15, name: "Lavender" },
    { id: 16, name: "Black" },
    { id: 17, name: "Gray" },
    { id: 18, name: "White" },
  ];



  const updateMutation = useMutation({
    mutationFn: (newData) =>
      updateManyItems("keywords_artworks", ["artworks_id", "keywords_id"], newData),
    onSuccess: (data) => {
  
      console.log("Update successful:", data);
    },
    onError: (err) => {
      // Handle error, e.g., show an error message
      console.error("Error updating items:", err);
    },
  });


  

  // Handle color selection
  const handleColorSelect = (color) => {
    if (selectedColors.some((c) => c.value === color.value)) {
      // Remove the color if it's already selected
      setSelectedColors((prev) => prev.filter((c) => c.value !== color.value));
    } else if (selectedColors.length < 3) {
      // Add the color if less than 3 are selected
      setSelectedColors((prev) => [...prev, color]);
    } else {
      alert("You can select up to 3 colors.");
    }
  };

  // Handle submit action
  const handleSubmit = () => {
    if (selectedColors.length > 0) {
      console.log(`Selected colors:`, selectedColors.map((c) => c.name).join(", "));
      setSelectedColors([]); // Reset selected colors

      const currentArtworkId = artworks[currentIndex]?.id

      updateMutation.mutate(
        selectedColors.map((id) => {
          // Using computed property names to set dynamic keys
          return {
            artworks_id: currentArtworkId,
            keywords_id: id.id,
          };
        })
      );

      if (currentIndex + 1 === config.artworksPerGame) {
        setGameFinished(true); // End the game
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else {
      alert("Please select at least one color.");
    }
  };

  // Handle skip action
  const handleSkip = () => {
    setSelectedColors([]); // Reset selected colors
    if (currentIndex + 1 === config.artworksPerGame) {
      setGameFinished(true); // End the game
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  // Handle game restart
  const handleRestart = () => {
    setGameFinished(false); // Reset the game state
    setCurrentIndex(0); // Reset the artwork index
    setSelectedColors([]); // Reset color selection
    refetch(); // Optionally fetch new artworks
  };

  if (isLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-container">
          <h2>Loading artworks...</h2>
        </div>
      </div>
    );
  }

  if (isError || artworks.length === 0) {
    return (
      <div className="modal-overlay">
        <div className="modal-container">
          <h2>Error loading artworks</h2>
          <button onClick={refetch}>Retry</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  if (gameFinished) {
    return (
      <div className="modal-overlay">
        <div className="modal-container">
          <h2>You've completed this round!</h2>
          <p>Do you want to continue with another {config.artworksPerGame} artworks?</p>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRestart}
            style={{ margin: "10px" }}
          >
            Yes, I Continue
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            style={{ margin: "10px" }}
          >
            No, Exit
          </Button>
        </div>
      </div>
    );
  }

  const currentArtwork = artworks[currentIndex % artworks.length];

  return (
    <div
  className="modal-overlay"
  onClick={(e) => {
    // Check if the click is on the overlay (not the modal container)
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  }}
>
      <div className="modal-container">
      <button className="modal-close" onClick={onClose}>
      ✖
    </button>
        <h2>Pick a Color for the Artwork</h2>
        <img
          src={`${process.env.REACT_APP_S3_BASE_IMG}artworks/${currentArtwork.id}/main_image/${currentArtwork.main_image}-450-300.jpg`}
          alt={currentArtwork.name}
          className="modal-image"
        />
        <h3>{currentArtwork.name}</h3>
        <h2>Pick up to 3 Colors</h2>
        <div className="color-options">
  {fetchedColors.map((color) => (
    <div
      key={color.id}
      className={`color-circle ${
        selectedColors.some((c) => c.id === color.id) ? "selected" : ""
      }`}
      style={{ backgroundColor: color.name }} // Use CSS color name
      onClick={() => {
        // Handle color selection
        if (selectedColors.some((c) => c.id === color.id)) {
          // Remove if already selected
          setSelectedColors((prev) => prev.filter((c) => c.id !== color.id));
        } else if (selectedColors.length < 3) {
          // Add if not already selected and under limit
          setSelectedColors((prev) => [...prev, color]);
        } else {
          alert("You can select up to 3 colors.");
        }
      }}
    >
      <span className="color-label">{color.name}</span>
    </div>
  ))}
</div>


        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
          <Button variant="outlined" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="text" color="secondary" onClick={handleSkip}>
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ArtworksColorModals;
