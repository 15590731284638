import React from "react";
import { Typography, Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { loadItems } from "../../api";
import Grid from '@mui/material/Grid2';
import CountItems from "./Widgets/CountItems";
import SimpleTableDashboard from "./SimpleTableGenericForWidget.js/SimpleTableDashboard";
import HoursByCity from "./Widgets/HoursByCity";
import { useParams } from "react-router-dom";
import { unslugify } from "../../utils/utils";

const LocalDashboards = () => {
  const { section } = useParams();
  const queryParams = { limit: 300 };
  const fieldsToFetch = "id,name, created_at, updated_at, created_by, last_updated_by, main_image";
  const userId = localStorage.getItem('userId');

  const query = useQuery({
    queryKey: ["items", section, queryParams, fieldsToFetch, ""],
    queryFn: () => loadItems(section, queryParams, fieldsToFetch, ""),
    keepPreviousData: true,
  });

  const sectionItems = query.data || [];
  const sectionTitle = unslugify(section);

  // Group the dashboard sections
  const GlobalActivitySection = () => (
    <>
      <Grid item size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section={section}
          title={`Last Updated ${sectionTitle}`}
          data={sectionItems} 
          sort_field="updated_at"
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section={section}
          title={`Last Added ${sectionTitle}`}
          data={sectionItems} 
          sort_field="created_at"
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Added Yesterday`}
          data={sectionItems} 
          count_when="yesterday" 
          added_or_updated="added"
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Added Today`} 
          data={sectionItems} 
          count_when="today" 
          added_or_updated="added"
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Updated Yesterday`} 
          data={sectionItems} 
          count_when="yesterday" 
          added_or_updated="updated"
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Updated Today`}
          data={sectionItems} 
          count_when="today" 
          added_or_updated="updated"
        />
      </Grid>
    </>
  );

  const PersonalActivitySection = () => (
    <>
      <Grid item size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section={section}
          title={`Last Updated ${sectionTitle} by Me`}
          data={sectionItems} 
          sort_field="updated_at" 
          by_me={userId}
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 6 }}>
        <SimpleTableDashboard 
          section={section} 
          title={`Last Added ${sectionTitle} by Me`}
          data={sectionItems} 
          sort_field="created_at" 
          by_me={userId}
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Added Yesterday by me`}
          data={sectionItems} 
          count_when="yesterday" 
          added_or_updated="added" 
          by_me={userId}
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Added Today by me`} 
          data={sectionItems} 
          count_when="today" 
          added_or_updated="added" 
          by_me={userId}
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Updated Yesterday by me`} 
          data={sectionItems} 
          count_when="yesterday" 
          added_or_updated="updated" 
          by_me={userId}
        />
      </Grid>
      <Grid item size={{ xs: 12, md: 3 }}>
        <CountItems 
          title={`${sectionTitle} Updated Today by me`} 
          data={sectionItems} 
          count_when="today" 
          added_or_updated="updated" 
          by_me={userId}
        />
      </Grid>
    </>
  );



  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      
      <Grid container spacing={4}>

        <Grid item size={{ xs: 12}}>
          <Grid container spacing={4}>
            {/* Global Activity Section */}
            <Grid item size={{ xs: 12}}>
              <Typography variant="h5" gutterBottom>Global Activity</Typography>
              <Grid container spacing={4}>
                <GlobalActivitySection />
              </Grid>
            </Grid>

            {/* Personal Activity Section */}
            <Grid item size={{ xs: 12}}>
              <Typography variant="h5" gutterBottom>My Activity</Typography>
              <Grid container spacing={4}>
                <PersonalActivitySection />
              </Grid>
            </Grid>
          </Grid>
        </Grid>


      
      </Grid>
    </Box>
  );
};

export default LocalDashboards;
