import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Import the form components
import QuickLocation from "./ScanAppForms.js/QuickLocation";
import FullLocation from "./ScanAppForms.js/FullLocation";
import MoreOptions from "./ScanAppForms.js/MoreOptions";

const ScanApp = () => {
  const [scannedCodes, setScannedCodes] = useState([]);
  const [currentScan, setCurrentScan] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [okMessage, setOkMessage]= useState("");

  // Define the regex patterns for valid codes
  const codePattern = /^[0-9]{6}$/; // Matches "000000" (6 digits)
  const caPattern = /^CA-\d{1,6}$/; // Matches "CA-000000"

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (currentScan.trim()) {
          // Validate the code against patterns
          if (codePattern.test(currentScan) || caPattern.test(currentScan)) {
            setScannedCodes((prev) => {
              const newCode = currentScan.trim();
              if (prev.includes(newCode)) {
                setErrorMessage(`Code ${newCode} already scanned!`);
                return prev;
              }
              return [...prev, newCode];
            });
          } else {
            console.warn("Invalid code scanned:", currentScan);
          }
          setCurrentScan("");
        }
      } else if (event.key.length === 1) {
        setCurrentScan((prev) => prev + event.key);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentScan, codePattern, caPattern]);

  const handleDeleteCode = (index) => {
    setScannedCodes((prev) => prev.filter((_, i) => i !== index));
  };

  const handleClearAll = () => {
    setScannedCodes([]);
  };

  const renderActiveTab = () => {
    const selectedRowIds = [
      ...new Set(
        scannedCodes.map((code) =>
          code.startsWith("CA-") ? code.replace("CA-", "") : code
        )
      ),
    ];


    
    const handleOkMessage = (message) => {
      setOkMessage(message); 
    };

    switch (activeTab) {
      case 0:
        return <QuickLocation section="artworks" selectedRowIds={selectedRowIds} />;
      case 1:
        return <FullLocation section="artworks" selectedRowIds={selectedRowIds} />;
      case 2:
        return <MoreOptions section="artworks" selectedRowIds={selectedRowIds} okMessage={handleOkMessage} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        {/* Left Section: Scanned Codes */}
        <Grid item xs={6}>
          <Paper sx={{ padding: 2, height: "80vh", overflowY: "auto" }}>
            <Typography variant="h6" gutterBottom>
              Scanned Codes
            </Typography>
            {scannedCodes.length > 0 ? (
              <>
                {scannedCodes.map((code, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                      padding: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography variant="body1">{code}</Typography>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteCode(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={handleClearAll}
                  sx={{ mt: 2 }}
                >
                  Clear All
                </Button>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No codes scanned yet.
              </Typography>
            )}
          </Paper>
        </Grid>

        {/* Right Section: Tabs */}
        <Grid item xs={6}>
          <Paper sx={{ padding: 2, height: "80vh", overflowY: "auto" }}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              variant="fullWidth"
              sx={{ marginBottom: 2 }}
            >
              <Tab label="Quick Location" />
              <Tab label="Full Location" />
              <Tab label="More Options" />
            </Tabs>
            {renderActiveTab()}
          </Paper>
        </Grid>
      </Grid>

      {/* Snackbar for Error Messages */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="warning" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
  open={!!okMessage}
  autoHideDuration={3000}
  onClose={() => setOkMessage("")}
>
  <Alert
    onClose={() => setOkMessage("")}
    severity="success"
    sx={{ width: "100%" }}
  >
    {okMessage}
  </Alert>
</Snackbar>
    </Box>
  );
};

export default ScanApp;
