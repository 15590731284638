import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel, Alert } from "@mui/material";
import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import {
  postItems,
  updateManyItems,
  updateManyItemsSameValue,
} from "../../api";

import { CURRENCY_OPTIONS } from "../../constants/selectConstants";
import StepOneSale from "./Steps/AddSalesStepOne";
import StepTwoSale from "./Steps/AddSalesStepTwo";
import StepThreeSale from "./Steps/AddSalesStepThree";
import StepFourSale from "./Steps/AddSalesStepFour";
import StepFiveSale from "./Steps/AddSalesStepFive";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"; // Import your API functions


const AddSalesModal = ({ section, selectedRowIds, handleClose }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    "Main Info",
    "Tax & VAT",
    "Account",
    "Collector",
    "Sales Info",
    "Submit"
  ];
  const [selectedSet, setSelectedSet] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [insertId, setInsertId] = useState(null);
  const [formData, setFormData] = useState({"artworks_id" : selectedRowIds });


  const [formDataEmployee, setFormDataEmployee] = useState({});
  const [entitiesOptions, setEntitiesOptions] = useState([]);
  const [establishmentsOptions, setEstablishmentsOptions] = useState([]);
  const [taxTypeOptions, setTaxTypeOptions] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);


  //setFormData({ "artworks_id": selectedRowIds });

console.log("entitiesOptions",entitiesOptions)
console.log("formDataEmployee",formDataEmployee)

  const handleSubmit = (event) => {
    event.preventDefault();
   postMutation.mutate(formData);
  };

  const foreignKey = "artworks_id";
  const locationTable = "sales";
  const locationKey = "sales_id";
  const junctionTable = "sales_artworks";


  /*
  const updateMutationJunction = useMutation({
    mutationFn: (newData) =>
      postItems("sales", newData),
    onSuccess: () => {

    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });*/


  const validateFormData = (formData, requiredFields) => {
    const incompleteFields = [];
  
    requiredFields.forEach((field) => {
      if (
        formData[field] === undefined || 
        formData[field] === null || 
        formData[field] === "" // Add other conditions as needed
      ) {
        incompleteFields.push(field);
      }
    });
  
    return {
      isComplete: incompleteFields.length === 0,
      incompleteFields,
    };
  };
  

  const requiredFieldsFormData = [
    "name",
    "currency",
    "sales_price_with_taxes",
    "sales_price_without_taxes",
    "tax_amount",
    "tax_rate",
    "entities_id",
    "accounts_id",
    "artpartners_id",
   "payment_method"
  ];

  const requiredFieldsFormDataEmployee = [
    "employees_id", // Add other fields related to employee as necessary
  ];
  



  const updateMutationEmployee = useMutation({
    mutationFn: (newDataEmployee) =>
      updateManyItems('sales_employees', ['sales_id', 'employees_id'], newDataEmployee),
    onSuccess: () => {
      setShowSuccess(true);
      console.log("success", showSuccess);
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });


  const postMutation = useMutation({
    mutationFn: (data) => postItems('sales', data),
    onSuccess: (data) => {
      const logEntry = JSON.parse(data.body);
      const insertID = logEntry.insertID;
      console.log(insertID);
      setInsertId(insertID);

    /* 
  updateMutationJunction.mutate(
        selectedRowIds.map((id) => ({
          [locationKey]: insertID,
          [foreignKey]: id,
        }))
      );*/


      updateMutationEmployee.mutate(
        formDataEmployee.employees_id.map((id) => ({
          'sales_id' : logEntry.insertID,
          'employees_id': id
        }))
      );

    },
    onError: (error) => {
      console.error("Error posting data:", error);
    },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChangeEmployee = (key, value) => {
    setFormDataEmployee((prevFormDataEmployee) => {
      const newFormDataEmployee = { ...prevFormDataEmployee, [key]: value };
      return newFormDataEmployee;
    });
    console.log('formDataEmployee',formDataEmployee);
  }

  const handleInputChange = (key, value) => {

    setFormData((prevFormData) => {
const newFormData = { ...prevFormData, [key]: value };

      const allClear = {
        sales_price_with_taxes: "",
        sales_price_without_taxes: "",
        tax_amount: "",
      };
      console.log("value", value);
      if (key === "entities_id") {
        if (value === 1) {
          return { ...newFormData, tax_rate_type: 1, tax_rate: 6, ...allClear };
        } else if (value === 5) {
          return {
            ...newFormData,
            tax_rate_type: 1,
            tax_rate: 8.875,
            ...allClear,
          };
        } else if (value === 3) {
          return {
            ...newFormData,
            tax_rate_type: 1,
            tax_rate: 7.7,
            ...allClear,
          };
        } else if (value === 6) {
          return {
            ...newFormData,
            tax_rate_type: 2,
            tax_rate: 20,
            ...allClear,
          };
        } else if (value === 4) {
          return {
            ...newFormData,
            tax_rate_type: 2,
            tax_rate: 16,
            ...allClear,
          };
        }
      } else if (key === "is_export") {
        if (value === true) {
          return { ...newFormData, tax_rate_type: 4, tax_rate: 0, ...allClear };
        } else {
          return { ...newFormData, tax_rate_type: 0, tax_rate: 0, ...allClear };
        }
      } else if (key === "sales_price_with_taxes" && value !== 0) {
        const priceHt = (value / (1 + formData.tax_rate / 100)).toFixed(2);
        const taxAmount = (value - priceHt).toFixed(2);

        return {
          ...newFormData,
          sales_price_without_taxes: priceHt,
          tax_amount: taxAmount,
        };
      } else if (key === "sales_price_without_taxes" && value !== 0) {
        const priceTtc = (value * (1 + formData.tax_rate / 100)).toFixed(2);
        const taxAmount = (priceTtc - value).toFixed(2);

        return {
          ...newFormData,
          sales_price_with_taxes: priceTtc,
          tax_amount: taxAmount,
        };
      } else if (key === "tax_rate" && value !== 0) {
        const priceTtc = formData.sales_price_with_taxes;
        const priceHt = (
          formData.sales_price_with_taxes /
          (1 + value / 100)
        ).toFixed(2);
        const taxAmount = priceTtc - priceHt;
        console.log("tax rate", value);

        return {
          ...newFormData,
          sales_price_with_taxes: priceTtc,
          sales_price_without_taxes: priceHt,
          tax_amount: taxAmount,
        };
      } else if (key === "margin" && value !== 0) {
        const vatOnMargin = value - value / (1 + formData.tax_rate / 100);
        const taxAmount = vatOnMargin.toFixed(2);
        const priceHt = formData.sales_price_with_taxes - taxAmount;

        return {
          ...newFormData,
          sales_price_without_taxes: priceHt,
          tax_amount: taxAmount,
        };
      }

      return newFormData;
    });

    console.log(formData);
  };

  if (selectedRowIds.length > 1) {
    return (
      <div>
        <Typography variant="h6">
          Sorry, you can't add more than one artwork per sale.
        </Typography>
      </div>
    );
  } else if (selectedRowIds.length < 1) {
    return (
      <div>
        <Typography variant="h6">
          Sorry, you have to select one artwork first.
        </Typography>
      </div>
    );
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <StepOneSale
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );
      case 1:
        return (
          <StepTwoSale
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );
      case 2:
        return (
          <StepThreeSale
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );
      case 3:
        return (
          <StepFourSale
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );

      case 4:
        return (
          <StepFiveSale
            formData={formData}
            formDataEmployee={formDataEmployee}
            handleInputChange={handleInputChange}
            handleInputChangeEmployee={handleInputChangeEmployee}
          />
        );
        case 5:
          const formDataValidation = validateFormData(formData, requiredFieldsFormData);


          const formDataEmployeeValidation = validateFormData(
            formDataEmployee,
            requiredFieldsFormDataEmployee
          );
          const allFormsComplete =
  formDataValidation.isComplete && formDataEmployeeValidation.isComplete;

  return (
    <div>
      {showSuccess ? (
        <div>
          <p>Data inserted successfully!</p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'center',
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Button
              sx={{m:2, width:'45%'}}
              variant="contained"
              color="secondary"
              href={`/sales/detail/${insertId}`}
            >
              View Entry
            </Button>
          </Box>
        </div>
      ) :  !allFormsComplete ? (
        <div>
          <Alert severity="warning" sx={{ mb: 2 }}>
            The form is incomplete. Please review the following fields:
            <ul>
              {formDataValidation.incompleteFields.map((field) => (
                <li key={`formData-${field}`}>{field.replace(/_/g, " ")}</li>
              ))}
              {formDataEmployeeValidation.incompleteFields.map((field) => (
                <li key={`formDataEmployee-${field}`}>
                  {field.replace(/_/g, " ")}
                </li>
              ))}
            </ul>
          </Alert>
        </div>
      ) :(
        <div>      <br />    <br />        
          <Alert severity="success" sx={{ mb: 2 }}>
          Ok, the form is complete. Do you want to add the sale?     </Alert>
          <br />

          <Button
           type="submit" disabled={updateMutationEmployee.isLoading}
           variant="contained"
           color="secondary"
           fullWidth
         >
                      {updateMutationEmployee.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Submit"
            )}
         </Button>
          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );

    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <Typography>{getStepContent(activeStep)}</Typography>
          <div>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            {activeStep < 5 &&
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>}
          </div>
        </div>
        {updateMutationEmployee.isError && <p>Error submitting form</p>}
      </form>
    </div>
  );
};

export default AddSalesModal;
