const themes = {
    blue: {
      primaryColor: '#1976d2', 
      secondaryColor: '#7619d2', 
    },
    dark_blue: {
      primaryColor: '#093170', 
      secondaryColor: '#65107f'
    },
    strong_green: {
      primaryColor: '#00544a', 
      secondaryColor: '#000a54', 
    },
    asse_green: {
      primaryColor: '#2c9c49', 
      secondaryColor: '#2c7e9c', 
    },
    purple: {
      primaryColor: '#9c27b0', 
      secondaryColor: '#b0273c', 
    },
  };
  
  export default themes;
