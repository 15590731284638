import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const HoursByCity = ({ city }) => {
  const getLocalTime = React.useMemo(() => {
    const options = {
      timeZone: {
        'New York': 'America/New_York',
        'London': 'Europe/London',
        'Paris': 'Europe/Paris',
        'Tokyo': 'Asia/Tokyo',
        'La Chaulme': 'Europe/Paris', // Use Paris as it's in the same timezone
        'Los Angeles': 'America/Los_Angeles',
      }[city] || 'UTC',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const now = new Date();

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: options.timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: options.timeZone,
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    });

    return {
      time: timeFormatter.format(now),
      date: dateFormatter.format(now),
    };
  }, [city]);

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AccessTimeIcon sx={{ mr: 1, color: 'primary.main' }} />
          <Typography variant="h6" component="div">
            {city}
          </Typography>
        </Box>
        <Typography variant="h3" color="primary" sx={{ mb: 1 }}>
          {getLocalTime.time}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {getLocalTime.date}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HoursByCity;
