import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { usePermissions } from '../../contexts/PermissionsContext';
import {
  Box,
  Checkbox,
  TextField,
  FormControlLabel,
  Button,
  CircularProgress,
  Typography
} from "@mui/material";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { loadItems, updateManyItems, postItems } from "../../api"; // Import your API functions
import AutocompleteWithQuery from "../Autocomplete/AutocompleteWithQuery";
import AutocompleteField from "../Autocomplete/AutocompleteField";

const AddToAnythingMtmModal = ({
  section,
  selectedRowIds,
  handleClose,
  target,
  reverse,
  moreOptions
}) => {
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { permissions } = usePermissions();


  console.log('moreOptions', moreOptions)

  let targetTable = target;

  if (target == "sets") {
    targetTable = "sets_of_" + section;
  }

  let junctionTableDefault = targetTable + "_" + section;
   if( section=='journalists' || section=='exhibitions' || section=='fairs' || section=='news' || section=='attached_images' || section=='attached_documents' || section=='selected_press' ){
       junctionTableDefault = section + "_" + targetTable;
    }
    else if(reverse==true){
      junctionTableDefault = section + "_" + targetTable;
    }


  

  let fromTable = section;
  let fromTableKey = fromTable + "_id";



  if(target=="artists_interest"){
    targetTable = fromTable == "artists" ? "artpartners" : "artists";
    junctionTableDefault= "artists_artpartners";
  }
  else if(target=="artists_collection"){
    targetTable = fromTable == "artists" ? "artpartners" : "artists";
    junctionTableDefault= "artpartners_artists";
  }
  else if(target=="all_artists_interest"){
    targetTable = fromTable == "all_artists" ? "artpartners" : "all_artists";
    junctionTableDefault= "all_artists_artpartners";
  }
  else if(target=="all_artists_collection"){
    targetTable = fromTable == "all_artists" ? "artpartners" : "all_artists";
    junctionTableDefault= "artpartners_all_artists";
  }
  let targetTableKey = targetTable + "_id";


console.log('permissions', permissions);

  const [junctionTable, setJunctionTable] = useState(junctionTableDefault);



  const handleAutoCompleteChange = (value) => {
    console.log("value target", value);
    setSelectedTarget(value);
  };

  const handleAutoCompletePermissionsChange = (value) => {
    window.alert(value);
    console.log("value target", value);
    setSelectedTarget(value);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setJunctionTable(target + "_" + section + "_storage");
    } else {
      setJunctionTable(target + "_" + section);
    }
    console.log(junctionTable);
  };

  const queryClient = useQueryClient();

  const {
    data: targetData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["items", targetTable, ["id", "name"]],
    queryFn: () =>
      loadItems(targetTable, { limit: 500, order_by: "updated_at" }, [
        "id",
        "name",
      ]),
  });




  const addHistory = useMutation({
    mutationFn: (data) =>
      postItems(`history_${targetTable}`, data, true, true),
    onSuccess: () => {
      console.log("History successfully recorded");
    },
    onError: (error) => {
      console.error("Error recording history:", error);
    },
  });
  

  const addHistorySource = useMutation({
    mutationFn: (data) =>
      postItems(`history_${fromTable}`, data, true, true),
    onSuccess: () => {
      console.log("History Same Value successfully recorded");
    },
    onError: (error) => {
      console.error("Error recording hsitory updateManyItemsSameValue:", error);
    },
  });



  const updateMutation = useMutation({
    mutationFn: (newData) =>
      updateManyItems(junctionTable, [targetTableKey, fromTableKey], newData),
    onSuccess: (data) => {
      // Handle success, e.g., show a success message or close the modal
      setShowSuccess(true);
      console.log("Update successful:", data);

      const totalIds = selectedRowIds.length;
      let isTruncated = false;
  
      // Always display the first 5 IDs
      let displayedIds = selectedRowIds.slice(0, 6).join(", ");
      const remainingCount = totalIds - 6;
  
      if (totalIds > 5) {
        displayedIds += ` and ${remainingCount} more`;
        isTruncated = true;
      }
  
  
      const historyTargetData = {
        field_name: fromTable,
        [`${targetTable}_id`]:  selectedTarget.id,
        old_value: `added ${totalIds} ${fromTable}`,
        new_value: displayedIds,
        event_type:2,
      };
  
      // Include `other_info` only if truncation occurred
      if (isTruncated) {
        historyTargetData.other_info = JSON.stringify({
            number: totalIds,
            ids: selectedRowIds
        });
    }
    
  
      addHistory.mutate(historyTargetData);
 console.log("selectedTarget.name",selectedTarget.name)
      const allValues = selectedRowIds.map((selectedRowId) => ({
        field_name: targetTable,
        [`${fromTable}_id`]: selectedRowId,
        old_value: `added to ${targetTable}`,
        new_value: `${selectedTarget.name} (${selectedTarget.id})`,
        other_info: JSON.stringify({
        id: selectedTarget.id
        }),
      }));

      
      console.log("allValues", allValues);
      addHistorySource.mutate(allValues); 



    },
    onError: (err) => {
      // Handle error, e.g., show an error message
      console.error("Error updating items:", err);
    },
  });







  const handleSubmit = (event) => {
    event.preventDefault();

    updateMutation.mutate(
      selectedRowIds.map((id) => {
        // Using computed property names to set dynamic keys
        return {
          [targetTableKey]: selectedTarget.id,
          [fromTableKey]: id,
        };
      })
    );
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <p>Error: {error.message}</p>;


  if (selectedRowIds.length < 1) {
    return (
      <div>
        <Typography variant="h6">
          Sorry, you have to select one item first.
        </Typography>
      </div>
    );
  }



  return (
    <Box>
      {showSuccess ? (
        <div>
          <p>Update successful!</p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Button
              sx={{ m: 2, width: "45%" }}
              onClick={() => setShowSuccess(false)}
              variant="contained"
              color="primary"
            >
              Add to Another {targetTable}
            </Button>
            <Button
              sx={{ m: 2, width: "45%" }}
              variant="contained"
              color="secondary"
              onClick={handleClose}
              component={Link}
              to={`/${targetTable}/detail/${selectedTarget.id}`}
            >
              View {targetTable}
            </Button>
          </Box>
        </div>
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                label="Selected IDs"
                value={selectedRowIds.join(", ")}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                margin="normal"
                sx={{ display: "none", my: 1 }}
              />
            </Box>
            <Box mb={2}>
              {((target == "fairs" || target == "exhibitions") && section=="artworks") ? (
                <FormControlLabel
                  key="is_storage"
                  control={<Checkbox />}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label="Storage"
                />
              ) : null}
            </Box>

           {moreOptions.selectComponent =="Autocomplete" ? (
                <AutocompleteField
                formData={[]}
                title={`Select ${targetTable}`}
                selectFile={`dynamic_json/${targetTable}.json`}
                selectFileKey="id"
                defaultLoadValue=""
                disabled={updateMutation.isLoading}
                sendBackJson={true}
                onValueChange={(newValue) => handleAutoCompleteChange(newValue)}
              />                          
           ): (<AutocompleteWithQuery
              formData={[]}
              selectFileKey="id"
              selectFile={targetTable}
              title={`Select ${targetTable}`}
              disabled={updateMutation.isLoading}
              sendBackJson={true}
              onValueChange={(newValue) => handleAutoCompleteChange(newValue)}
            />)}

            <Box sx={{ width: "100%", my: 1 }}>
              You are going to assign {selectedRowIds.length} {section} to{" "}
              {targetTable} <b>{selectedTarget?.name}</b>{" "}
              {isChecked && "in storage"}
            </Box>
            <Button type="submit" disabled={updateMutation.isLoading}>
              {updateMutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Submit"
              )}
            </Button>
            {updateMutation.isError && <p>Error submitting form</p>}
          </form>
        </div>
      )}
    </Box>
  );
};

export default AddToAnythingMtmModal;
