import React, { useState, useEffect, useMemo, useRef } from 'react';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import CustomGlobalEverythingPDF from '../DocumentRender/CustomGlobalEverythingPDF';
import { Button, TextField, FormControl,Checkbox, FormLabel, RadioGroup, FormControlLabel, Radio, Slider } from '@mui/material';
import pdfApi from '../Api/pdfApi';
import useModelLoader from '../../../hooks/useModelLoader';
import { unslugify } from '../../../utils/utils';
import { renderPdfOptionsCheckboxes, getPdfOptionsFieldKeys } from '../utils/pdfUtils';

function MakeGlobalPdfFormModal({ itemData, query, section, selectedRowIds }) {
  const [entriesPerPage, setEntriesPerPage] = useState(1);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({main_image: true});
  const [checkboxCustomValues, setCheckboxCustomValues] = useState({});
  const [isTableView, setIsTableView] = useState(false);
  const [configOptions, setConfigOptions] = useState({
    title: '', // Default title
    showImage: true, // Default image visibility
    imageQuality: '50-40', // Default image quality
    imageSize: 100,
    showTitleAndPage: false
  });


  const handleRadioChange = (event) => {
    setEntriesPerPage(event.target.value);
  };


  const handleConfigChange = (key, value) => {
    setConfigOptions((prev) => ({ ...prev, [key]: value }));
  };

  const { modelData, isLoading: isModelDataLoading } = useModelLoader(section);

  useEffect(() => {
    if (modelData?.fields) {
      const initialCheckboxValues = modelData.fields.reduce((acc, field) => {
        if (field.pdfOptions) {
          acc[field.key] = field.pdfOptions === 'default';
        }
        return acc;
      }, {});
      setCheckboxValues(initialCheckboxValues);
    }
  }, [modelData?.fields]);


  // Handle checkbox changes
  const handleCheckboxChange = (event) => {
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked
    });
  };


  const handleCustomOptionChange = (event) => {
    setCheckboxCustomValues({
      ...checkboxCustomValues,
      [event.target.name]: event.target.checked
    });
  };

  // Function to render checkboxes for fields with 'pdfOptions'
  const renderPdfOptionsCheckboxes = () => {
    return modelData?.fields
      .filter(field => field.pdfOptions)
      .map(field => (
        <FormControlLabel
          key={field.key}
          control={
            <Checkbox
              checked={checkboxValues[field.key] || false}
              onChange={handleCheckboxChange}
              name={field.key}
            />
          }
          label={unslugify(field.title)}
        />
      ));
  };

  


    const timestamp = new Date().getTime(); 


    useEffect(() => {
      if (selectedRowIds && selectedRowIds.length > 0) {
        const fetchItems = async () => {
          try {
            setIsLoading(true);
            const idsString = selectedRowIds.join(',');
            const selectedFields = getPdfOptionsFieldKeys(modelData);
  
            if (selectedFields.length === 0) {
              console.warn('No fields with pdfOptions=true found.');
              return;
            }
  
            const queryParams = `table=${section}&ids=${idsString}&fields=${selectedFields.join(',')}`;
            const response = await pdfApi.get(`?${queryParams}`);
            setData(response.data);
          } catch (err) {
            console.error('Error fetching items:', err);
            setError(err);
          } finally {
            setIsLoading(false);
          }
        };
  
        fetchItems();
      }
    }, [section, selectedRowIds, modelData]);



    // Function to handle table view toggle
    const handleTableViewChange = (event) => {
      setIsTableView(event.target.checked);
    };



    const documentMemo = useMemo(() => {
      if (!data) return null;


      const pdfProps = {
        configOptions: configOptions || {
          title: '',
          showImage: true,
          imageQuality: '50-40',
          imageSize: 100,
          showTitleAndPage: false
        },
        checkedFields: checkboxValues || {},
        checkedCustomFields: checkboxCustomValues || {},
        entriesPerPage: parseInt(entriesPerPage) || 1,
        section: section || '',
        data: data || [],
        isTableView: isTableView || false
      };
    
      let ComponentToCall = CustomGlobalEverythingPDF;
      return (
        <ComponentToCall
        {...pdfProps}
        />
      );
    }, [entriesPerPage, section, data, checkboxValues, checkboxCustomValues, isTableView, configOptions]);

  useEffect(() => {
    // Reset the blob URL to force regeneration
    setPdfBlobUrl(null);
  }, [documentMemo, checkboxValues]);

  const selectedRowCount = selectedRowIds?.length || 0;



  // Early return if required data is missing
  if (!modelData) {
    return null;
  }

  
  if (isLoading || isModelDataLoading) {
    return <div>Loading...</div>; // Handle loading state for both API data and model data
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {/* Form Section */}
     
      <div style={{ width: '50%' }}>
     
      {selectedRowCount} records in {section} selected
        <form>
          {/* Options for entries per page */}
          <Button style={{ marginRight: '10px', marginTop: '10px', float: 'right'}} variant="outlined" size="small" href={pdfBlobUrl} download={`Ceysson & Bénétière ${configOptions.title}.pdf`}>
              Download PDF
        </Button>
        <FormControl component="fieldset">
        <FormLabel>Title and name of your pdf</FormLabel>
        <TextField style={{ width: '80%'}} 
        id="Title" key="Title" label="Title" variant="outlined" 
        onBlur={(e) => {
          const value = e.target.value.trim();
          handleConfigChange('title', value || `${section} checklist`);
        }}
        />
                <FormControlLabel
                key="showTitleAndPage"
                control={
                  <Checkbox
                    checked={configOptions.showTitleAndPage || false}
                    onChange={(e) => handleConfigChange('showTitleAndPage', e.target.checked)}
                    name="showTitleAndPage"
                  />

                }
                
                label="Show Title and Page on every page"
              />

          <FormControlLabel
  control={<Checkbox checked={isTableView} onChange={handleTableViewChange} />}
  label="Display as Table"
/>
<FormLabel>Image</FormLabel>
        <FormControlLabel
                key="showImage"
                control={
                  <Checkbox
                    checked={configOptions.showImage || false}
                    onChange={(e) => handleConfigChange('showImage', e.target.checked)}
                    name="showImage"
                  />

                }
                
                label="Show Image"
              />
{configOptions.showImage && (
            <div>
              <FormLabel>Image Quality</FormLabel>
              <RadioGroup
                value={configOptions.imageQuality}
                onChange={(e) => handleConfigChange('imageQuality', e.target.value)}
                row
              >
                <FormControlLabel
                  value="900-600"
                  control={<Radio />}
                  label="High Quality"
                />
                <FormControlLabel
                  value="450-300"
                  control={<Radio />}
                  label="Medium Quality"
                />
                <FormControlLabel
                  value="132-80"
                  control={<Radio />}
                  label="Low Quality"
                />
                                <FormControlLabel
                  value="50-40"
                  control={<Radio />}
                  label="Very Low Quality"
                />
              </RadioGroup>
            </div>
          )}
{configOptions.showImage && (
<>
<FormLabel>Image Size</FormLabel>
            <Slider
              aria-label="Image Size %"
              value={configOptions.imageSize}
              onChange={(e, value) =>
                setConfigOptions((prev) => ({ ...prev, imageSize: value }))
              }
              valueLabelDisplay="auto"
              step={1}
              min={10}
              max={100}
              marks
              style={{ marginBottom: '1rem', width:'80%' }}
            /> </>    
          )}
            
{isTableView ? (       <>  <FormLabel>Rows Per Page</FormLabel>
          <Slider
            aria-label="Rows Per Page"
            value={entriesPerPage}
            onChange={handleRadioChange}
            valueLabelDisplay="auto"
            step={1}
            min={3}
            max={40}
            marks
            style={{ marginBottom: '1rem', width:'80%'  }}
          /></> ) : (<>
  <FormLabel >Entries Per Page</FormLabel>
            <RadioGroup
              aria-label="entries-per-page"
              name="entriesPerPage"
              value={entriesPerPage}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="1 Entry per Page" />
              <FormControlLabel value="4" control={<Radio />} label="4 Entries per Page" />
              <FormControlLabel value="10" control={<Radio />} label="10 Entries per Page" />
            </RadioGroup>
            {modelData?.pdfConfig?.hasBarcode && (
        <div>
    <FormControlLabel
          key="barcode"
          control={
            <Checkbox
              checked={checkboxCustomValues["barcode"] || false}
              onChange={handleCustomOptionChange}
              name="barcode"
            />
          }
          label="Barcode"
        />
      
        </div>
      )}
                        <FormControlLabel
          key="cblogo"
          control={
            <Checkbox
              checked={checkboxCustomValues["cblogo"] || false}
              onChange={handleCustomOptionChange}
              name="cblogo"
            />
          }
          label="CB Logo on Labels"
        /></>)}


            {modelData?.fields && (
        <div>
          {renderPdfOptionsCheckboxes()}
        </div>
      )}
          </FormControl>
          <br />
  
          <BlobProvider
  checkedFields={checkboxValues}
  checkedCustomFields={checkboxCustomValues}
  document={documentMemo}
  key={entriesPerPage}
  configOptions={configOptions}
>
  {({ url, loading }) => (
    <>
      <br />
      <Button
        style={{
          marginBottom: '100px',
          marginRight: '20px',
          float: 'right',
        }}
        variant="outlined"
        size="small"
        href={loading ? undefined : url} // Prevent click when loading
        download={loading ? undefined : `Ceysson & Bénétière ${configOptions.title}.pdf`} // Prevent download when loading
        disabled={loading} // Disable button during loading
      >
        {loading ? 'Preparing PDF...' : 'Download PDF'}
      </Button>
      {(() => {
        if (url && url !== pdfBlobUrl) setPdfBlobUrl(url);
        return null;
      })()}
    </>
  )}
</BlobProvider>

        </form>
      </div>
  
      {/* PDF Preview Section */}
      <div style={{ width: '50%' }}>
        {pdfBlobUrl && (
          <iframe 
            src={pdfBlobUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="PDF Preview"
          />
        )}
      </div>
      
    </div>
  );
  
}

export default MakeGlobalPdfFormModal;
