import { useState, useEffect } from "react";

const useModelsLoader = (sections) => {
  const [modelData, setModelData] = useState({});
  const [filteredFields, setFilteredFields] = useState([]);

  useEffect(() => {
    if (!sections || sections.length === 0) return;

    const importModels = async () => {
      const models = {};
      const fields = [];

      for (let section of sections) {
        let modelFileExtension = "json";
        let model = section;

        if (
          /^sets_of_/.test(section) ||
          /^narratives_/.test(section) ||
          /^narrative_/.test(section) ||
          /^locations/.test(section)
        ) {
          modelFileExtension = "js";
        } else if (/^history_/.test(section)) {
          model = "history";
        } else if (/^final_invoices_entities_/.test(section)) {
          model = "final_invoices_entities";
        }

        try {
          const importedModel = await import(`../model/${model}.${modelFileExtension}`);

          if (importedModel.default) {
            models[section] = importedModel.default;

            // Extract fields if available
            if (importedModel.default.fields) {
              const sectionFields = importedModel.default.fields.filter(
                (field) => field.showInAddModal
              );
              fields.push(...sectionFields);
            }
          }
        } catch (error) {
          console.error(`Error importing model for section "${model}":`, error);
        }
      }

      setModelData(models);
      setFilteredFields(fields);
    };

    importModels();
  }, []); // Only runs when `sections` changes

  return { modelData, filteredFields };
};

export default useModelsLoader;
