import { createTheme } from '@mui/material/styles';

const getTheme = (primaryColor, secondaryColor, mode = 'light') => {
  return createTheme({
    palette: {
      mode, // 'light' or 'dark'
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      ...(mode === 'dark' && {
        background: {
          default: '#121212',
          paper: '#1e1e1e',
        },
        text: {
          primary: '#ffffff',
          secondary: '#b0bec5',
        },
      }),
    },
  });
};

export default getTheme;
