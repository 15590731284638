import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  FormControl,
  TextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { usePermissions } from '../../contexts/PermissionsContext';
import { useCache } from "../../contexts/CacheContext"; 

const AutocompleteField = ({
  title,
  selectFile,
  selectFileKey = "id",
  conditionnalFileLoad,
  formData=null,
  value,
  sendBackJson,
  selectFreesolo,
  disabled = false,
  onValueChange,
  defaultLoadValue='LU_',
  setNone=false
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState();
  const { noCache } = useCache(); // Get the cache state

  const { permissions } = usePermissions();
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  

  useEffect(() => {
    if (selectFile) {
      let fileName = selectFile;
      if (conditionnalFileLoad && formData[conditionnalFileLoad]) {
        fileName = selectFile.replace(
          "_REPLACE_",
          `${formData[conditionnalFileLoad]}_`
        );
      }
      else{
        fileName = selectFile.replace(
          "_REPLACE_",
          defaultLoadValue
        );
      }
        const fetchData = async () => {
          const cacheBuster = noCache ? `?${Date.now()}` : '';
          const response = await fetch(
            `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${fileName}${cacheBuster}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();

          let filteredData = data;
        if (selectFile === "dynamic_json/permissions.json" && Array.isArray(permissions)) {
          filteredData = data.filter(option =>
            permissions.includes(option.name)
          );
          console.log("filteredData", filteredData)
        }


          if (setNone) {
            setOptions([{ id: 'SetNull', name: 'None' }, ...filteredData]);
          } else {
            setOptions(filteredData);
          }
        };
        fetchData().catch((error) => console.error("Fetching error:", error));
    }
  }, [formData, selectFile, title]);


  // Update the internal state when the external value changes
  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleAutocompleteChange = (event, newValue) => {
    console.log('log from auto', newValue);
    if(sendBackJson){
      onValueChange(newValue ? newValue : '');
    }else{
    onValueChange(newValue ? newValue[selectFileKey] : '');
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (selectFreesolo) {
      onValueChange({n: newInputValue});
    } else {
        if (newInputValue === '') {
        onValueChange(null); 
      } else {
        onValueChange(newInputValue);
      }
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <MuiAutocomplete
        sx={{ width: "100%", my: 1 }}
        disabled={disabled}        
        freeSolo={selectFreesolo}
        value={inputValue} 
        options={options}
        getOptionLabel={(option) => option.n || option.name || ""}
        groupBy={(option) => option.categorie}
        onChange={handleAutocompleteChange}
        onInputChange={handleInputChange}
        inputValue={inputValue || ''} 
        renderInput={(params) => (
          
          <TextField
            {...params}
            label={title}
            value={inputValue} 
          />
        )}
      />
    </FormControl>
  );
};

export default React.memo(AutocompleteField);
