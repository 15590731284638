import React, { useState } from "react";
import ArtworksColorModals from "./ArtworksColorModals";
import { Paper } from "@mui/material";

const GameModals = () => {
  const [activeModal, setActiveModal] = useState(null);

  const closeModal = () => setActiveModal(null);

  return (
    <Paper
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <h1>Game Modals</h1>
      <button onClick={() => setActiveModal("colorPicker")}>
        Open Color Picker Modal
      </button>

      {activeModal === "colorPicker" && <ArtworksColorModals onClose={closeModal} />}
    </Paper>
  );
};

export default GameModals;
