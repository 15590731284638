import React, { useMemo } from "react";
import { Card, CardContent, Typography } from "@mui/material";

const CountItems = ({ title="total", data, added_or_updated="updated", count_when="today", by_me=null }) => {
  const count = useMemo(() => {
    if (!data || !Array.isArray(data)) return 0;

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    return data.filter(item => {
      // Choose which date and user field to check based on added_or_updated
      const dateToCheck = added_or_updated === "updated" ? item.updated_at : item.created_at;
      const userToCheck = added_or_updated === "updated" ? item.last_updated_by : item.created_by;
      
      const itemDate = new Date(dateToCheck);
      const itemDateOnly = new Date(
        itemDate.getFullYear(), 
        itemDate.getMonth(), 
        itemDate.getDate()
      );

      // Check if the user matches (if by_me is provided)
      const userMatches = by_me ? userToCheck === by_me : true;

      // Check if the date matches
      let dateMatches = false;
      if (count_when === "today") {
        dateMatches = itemDateOnly.getTime() === today.getTime();
      } else if (count_when === "yesterday") {
        dateMatches = itemDateOnly.getTime() === yesterday.getTime();
      }

      return dateMatches && userMatches;
    }).length;
  }, [data, count_when, added_or_updated, by_me]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h2" color="primary">
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CountItems;
