import React, { useState } from 'react';
import { Button, Modal, Box } from "@mui/material";
import modalConfig, { modalStyles } from './ModalConfig'; // Importing modalStyles

const ModalWithTrigger = ({ type, label, section, selectedRowIds, handleRefresh}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ModalComponent = modalConfig[type]?.component;
  const modalStyle = modalConfig[type]?.style || modalConfig.defaultStyle;
  const isLarge = modalConfig[type]?.style === modalStyles.large;
  const finalTarget = modalConfig[type]?.finalTarget || type;
  const manyOrFk = modalConfig[type]?.manyOrFk || "many";
  const moreOptions = modalConfig[type]?.moreOptions || "";
  const prefixWithTargetName = modalConfig[type]?.prefixWithTargetName;
  const reverse = modalConfig[type]?.reverse;

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
    if(type=="locations_artworks"){
      handleRefresh();
    }
  };


  return (
    <>
      <Button sx={{ mb: 1 }} onClick={handleOpen}>
        {label}
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {ModalComponent && <ModalComponent section={section} selectedRowIds={selectedRowIds} target={finalTarget} isLarge={isLarge} manyOrFk={manyOrFk} reverse={reverse} prefixWithTargetName={prefixWithTargetName} moreOptions={moreOptions}/>}
        </Box>
      </Modal>
    </>
  );
};

export default ModalWithTrigger;
