import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Typography
} from "@mui/material";
import { postItems, updateManyItems, updateManyItemsSameValue } from "../../api";
import AutocompleteField from "../Autocomplete/AutocompleteField";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"; // Import your API functions

const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const AddLocationModalForm = ({ section, selectedRowIds, handleClose }) => {
  const [selectedSet, setSelectedSet] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [insertId, setInsertId] = useState(null);
  const [formData, setFormData] = useState({establishments_id: null, shippers_id: null, offsite_places_id: null, location_shelf:"", location_details:"" });
  const [finalOptions, setFinalOptions] = useState([]);
  const [locationStatus, setLocationStatus] = useState([]);
  const [locationResponsability, setLocationResponsability] = useState([]);




 useEffect(() => {
    const fetchAllData = async () => {
      try {
        const urls = [
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}locations_precise_status.json`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}locations_status.json?12223`,
          `${process.env.REACT_APP_GLOBAL_MAPPING_URL}locations_responsability.json`,
        ];
        const [finalOptions, locationStatus, locationResponsability] =
          await Promise.all(urls.map(fetchData));
        console.log(finalOptions, locationStatus, locationResponsability);
        setFinalOptions(finalOptions);
        setLocationStatus(locationStatus);
        setLocationResponsability(locationResponsability);
      } catch (error) {
        console.error("Fetching error:", error);
      }
    };

    fetchAllData();
  }, []);

  const foreignKey = section + "_id";
  const locationTable = "locations_" + section;
  const locationKey = locationTable + "_id";
  const junctionTable = "locations_" + section + "_" + section;
  const noHistory = true;

  const [filteredFinalOptions, setFilteredFinalOptions] = useState(finalOptions);

  useEffect(() => {
    // Filter options based on selected status and responsability
    const filteredOptions = finalOptions.filter((option) => {
      const statusMatches = Array.isArray(option.status)
        ? option.status.includes(parseInt(formData.location_status))
        : option.status === formData.location_status;

      const responsabilityMatches = Array.isArray(option.responsability)
        ? option.responsability.includes(parseInt(formData.location_responsability))
        : option.responsability === formData.location_responsability;

      return statusMatches && responsabilityMatches;
    });

    setFilteredFinalOptions(filteredOptions);
  }, [formData.location_status, formData.location_responsability]);

  const updateMutationJunction = useMutation({
    mutationFn: (newData) =>
      updateManyItems(junctionTable, [locationKey, foreignKey], newData),
    onSuccess: () => {
      // Handle success after updating junction table
      setShowSuccess(true);
      console.log("success", showSuccess);
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });




  const updateMutationTarget = useMutation({
    mutationFn: (newData) =>
    updateManyItemsSameValue(section,formData, selectedRowIds),
    onSuccess: () => {
      // Handle success after updating junction table
      setShowSuccess(true);
      console.log("success", showSuccess);
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });




  const postMutation = useMutation({
    mutationFn: (data) => postItems(locationTable, data, noHistory),
    onSuccess: (data) => {
      const logEntry = JSON.parse(data.body);
      const insertID = logEntry.insertID;

      // After successfully posting the new item, update the junction table
      updateMutationJunction.mutate(
        selectedRowIds.map((id) => ({
          [locationKey]: insertID,
          [foreignKey]: id,
        }))
      );


      updateMutationTarget.mutate();

    },
    onError: (error) => {
      console.error("Error posting data:", error);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(filteredFinalOptions,formData)
   postMutation.mutate(formData);
  };

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
    if (key == "establishments_id") {
      setFormData({
        ...formData,
        location_shelf: "",
        establishments_id: value,
      });
    }
    console.log(key, value);
    console.log("formData", formData);
  };


  if (selectedRowIds.length < 1) {
    return (
      <div>
        <Typography variant="h6">
          Sorry, you have to select one item first.
        </Typography>
      </div>
    );
  }


  return (
    <>
      {showSuccess ? (
        <>
          <div>
            <p>Update successful!</p>
          </div>
          <Box>
            <Button
              sx={{ m: 2, width: "45%" }}
              onClick={() => setShowSuccess(false)}
              variant="contained"
              color="primary"
            >
              Add another location
            </Button>
          </Box>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal" sx={{ my: 1 }}>
              <InputLabel id="status-label">Choose Status</InputLabel>
              <Select
                labelId="status-label"
                label="Choose Status"
                value={formData.location_status || ""}
                sx={{ width: "100%", my: 1 }}
                title="Choose Status"
                onChange={(e) => handleInputChange("location_status", e.target.value)}
              >
                {locationStatus?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth margin="normal" sx={{ my: 1 }}>
              <InputLabel id="responsability-label">
                Choose Responsability
              </InputLabel>
              <Select
                labelId="status-label"
                label="Choose Responsability"
                value={formData.location_responsability || ""}
                sx={{ width: "100%", my: 1 }}
                onChange={(e) =>
                  handleInputChange("location_responsability", e.target.value)
                }
              >
                {locationResponsability.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl fullWidth margin="normal" sx={{ my: 1 }}>
              <InputLabel id="precise_status-label">
                Choose Precise Status
              </InputLabel>
              <Select
                labelId="status-label"
                label="Choose Precise Status"
                value={formData.location_precise_status || ""}
                sx={{ width: "100%", my: 1 }}
                onChange={(e) =>
                  handleInputChange("location_precise_status", e.target.value)
                }
                disabled={filteredFinalOptions.length === 0}
              >
                {filteredFinalOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {formData.location_precise_status === "1" ? (
            <AutocompleteField
              title="Choose location"
              selectFile="establishments_code_short_active.json"
              selectFreesolo="false"
              disabled={!formData.location_status || formData.location_status === ""}
              conditionnalFileLoad={false}
              formData={formData}
              onValueChange={(value) =>
                handleInputChange("establishments_id", value)}
                />
            ) : formData.location_precise_status === "2" ? (
              <AutocompleteField
              title="Choose location"
              selectFile="dynamic_json/offsite_places.json"
              selectFreesolo="false"
              disabled={!formData.location_status || formData.location_status === ""}
              conditionnalFileLoad={false}
              formData={formData}
              onValueChange={(value) =>
                handleInputChange("offsite_places_id", value)}
                />
            ): formData.location_precise_status === "6" ? (
              <AutocompleteField
              title="Choose location"
              selectFile="dynamic_json/shippers.json"
              selectFreesolo="false"
              disabled={!formData.location_status || formData.location_status === ""}
              conditionnalFileLoad={false}
              formData={formData}
              onValueChange={(value) =>
                handleInputChange("shippers_id", value)}
                />
            ):null}
            <br />
            {formData.location_status === "1" && formData.location_responsability === "1" && formData.location_precise_status === "1" &&(
              <AutocompleteField
                inputRef="shelfinput"
                title="Choose Shelf"
                selectFile="logistic_codes/_REPLACE_logistic_codes.json"
                selectFreesolo="true"
                selectFileKey="n"
                value={formData.shelf || ""}
                disabled={!formData.location_status || formData.location_status === ""}
                conditionnalFileLoad="establishments_id"
                formData={formData}
                onValueChange={(value) => handleInputChange("location_shelf", value)}
              />
            )}
            <br />
            <TextField
              sx={{ width: "100%", my: 1 }}
              key="location_details"
              label="location_details"
              value={formData.details}
              variant="outlined"
              margin="normal"
              onChange={(e) => handleInputChange("location_details", e.target.value)}
            />

            <Button type="submit" disabled={updateMutationJunction.isLoading}>
              {updateMutationJunction.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Submit"
              )}
            </Button>
            {updateMutationJunction.isError && <p>Error submitting form</p>}
          </form>
        </>
      )}
    </>
  );
};

export default AddLocationModalForm;
