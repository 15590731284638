import React, { useState,useMemo, memo } from 'react';
import { Button, Modal, Box } from "@mui/material";
import pdfModalConfig, { modalStyles } from './pdfModalConfig'; // Importing modalStyles

const PdfModalTrigger = ({ type, label, section, itemData, selectedRowIds, fromTable, fromId, ...additionalProps}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ModalComponent = pdfModalConfig[type]?.component;
  const modalStyle = pdfModalConfig[type]?.style || pdfModalConfig.defaultStyle;
  const isLarge = pdfModalConfig[type]?.style === modalStyles.large;
  const query = pdfModalConfig[type]?.query || false;
  const config = pdfModalConfig[type];

  const idsToPost = selectedRowIds;

  console.log('additionalPropsTrigger PDF', additionalProps)
  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button sx={{ mb: 1 }} onClick={handleOpen}>
        {label}
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        
      >
        <Box sx={modalStyle}>
          {ModalComponent && <ModalComponent config={config} section={section} selectedRowIds={idsToPost} itemData={itemData} query={query} isLarge={isLarge} fromTable={fromTable} fromId={fromId} {...additionalProps}/>}
        </Box>
      </Modal>
    </>
  );
};

export default React.memo(PdfModalTrigger);
