import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import { fetchAuthSession, getCurrentUser, signOut, fetchUserAttributes } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import axios from 'axios';
import awsmobile from '../aws-exports';

// Configure Amplify
Amplify.configure(awsmobile);

function useAuth() {
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [authOkay, setAuthOkay] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('CognitoJWTToken') || null);
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const [permissions, setPermissions] = useState(null);
  const [userEmail, setUserEmail] = useState(null); // Add this state


  const navigate = useNavigate();

  // Create an Axios instance with the base URL and headers
  const api = axios.create({
    baseURL: process.env.REACT_APP_APIGETAWAY_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  // Function to store JWT token in localStorage
  const storeToken = (jwtToken) => {
    const decodedToken = JSON.parse(atob(jwtToken.split('.')[1]));
    localStorage.setItem('CognitoJWTToken', jwtToken);
    setToken(jwtToken);
    localStorage.setItem('TokenExpiry', decodedToken.exp.toString());
  };

  // Function to check if the token has expired
  const isTokenExpired = () => {
    const expiryTime = localStorage.getItem('TokenExpiry');
    if (!expiryTime) return true;
    return Math.floor(Date.now() / 1000) > parseInt(expiryTime, 10);
  };

  const handleLogout = async (message = null) => {
    try {
      // Clear all local storage first
      localStorage.removeItem('StoredUserDataFromDb41');
      localStorage.removeItem('CognitoJWTToken');
      localStorage.removeItem('TokenExpiry');
      localStorage.removeItem('userId');
      
      // Clear all states
      setUser(null);
      setAuthOkay(false);
      setToken(null);
      setUserId(null);
      setPermissions(null);
      setUserEmail(null);
      localStorage.setItem('authMessage', message);

      // Sign out last
      await signOut({ global: true });
      
      // Navigate after signout
      navigate('/login');
    } catch (error) {
    //  console.error('Error signing out:', error);
    }
  };

  // Function to check authentication status
  const checkAuth = async () => {
    try {
     // console.log('Checking authentication...');
      const session = await fetchAuthSession();
      const currentUser = await getCurrentUser();
      const attributes = await fetchUserAttributes(); // Get user attributes
      
      if (session?.tokens?.idToken) {
        setUser(currentUser);
        setUserEmail(attributes.email); // Store email
        storeToken(session.tokens.idToken.toString());
        setAuthOkay(true);
      } else {
        throw new Error('No valid session');
      }
    } catch (error) {
     // console.error('Error in checkAuth:', error);
      setUser(null);
      setUserEmail(null);
      setAuthOkay(false);
      setToken(null);
    } finally {
      setAuthLoading(false);
    }
  };


  useEffect(() => {
    const authListener = (data) => {
      const { event } = data.payload;
     // console.log('Auth Event:', event, data.payload);
  
      switch (event) {
        case 'signedIn':
        //  console.log('User signed in');
          checkAuth();
          break;
        case 'signedOut':
         // console.log('User signed out');
          setUser(null);
          setAuthOkay(false);
          setToken(null);
          setUserId(null);
          setPermissions(null);
          setUserEmail(null);
          break;
        case 'tokenRefresh':
         // console.log('Token refreshed');
          checkAuth();
          break;
        case 'signInFailure':
          //console.log('Sign in failure:', data.payload.data);
          setAuthOkay(false);
          break;
        default:
          break;
      }
    };
  
    // Store the unsubscribe function
    const unsubscribe = Hub.listen('auth', authListener);
    checkAuth();
  
    // Use the unsubscribe function in cleanup
    return () => {
      unsubscribe();
    };
  }, []);
  
/*
  // Effect to fetch user data from API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && token && !userId && userEmail) { // Add userEmail check
          console.log('Fetching user data from API...');
          const response = await api.get(
            `?table=users&loopforfields=loop&fields=id,name,users_id,is_active,employees_id,theme,theme_mode&email=${userEmail}` // Use userEmail instead
          );
          const userData = response.data[0];
          if (userData) {
            setUserId(userData.id);
            localStorage.setItem('userId', userData.id);
            localStorage.setItem('employeeId', userData.employeeId);
            localStorage.setItem('StoredUserDataFromDb', JSON.stringify(userData));
            console.log('User data fetched:', userData);
          } else {
            console.error('User not found in API');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (authOkay && user && !userId && userEmail) { // Add userEmail check
      fetchUserData();
    }
  }, [authOkay, user, token, userId, userEmail]); 
*/

  // Effect to fetch user data from API
useEffect(() => {
  const fetchUserData = async () => {
    try {
      localStorage.setItem('authMessage', '');
      if (user && token && !userId && userEmail) {
        //console.log('Fetching user data from API...');
        const response = await api.get(
          `?table=users&loopforfields=loop&fields=id,name,users_id,is_valid,employees_id,theme,theme_mode&email=${userEmail}`
        );

        const userData = response.data[0];
        if (userData) {
          if (userData.is_valid != 1) {
          //  console.error('User is inactive');
            handleLogout('You have been logged out because no data was found.');
          } else {
            setUserId(userData.id);
            localStorage.setItem('userId', userData.id);
            localStorage.setItem('employeeId', userData.employees_id);
            localStorage.setItem('StoredUserDataFromDb', JSON.stringify(userData));



            //console.log('User data fetched:', userData);
          }
        } else {
         // console.error('No user data returned from API');
          handleLogout('You have been logged out, no data was found.');
        }
      }
    } catch (error) {
     // console.error('Error fetching user data:', error);
     handleLogout('You have been logged out because no data found.');
    }
  };

  if (authOkay && user && !userId && userEmail) {
    fetchUserData();
  }
}, [authOkay, user, token, userId, userEmail]);





  // Effect to fetch permissions
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        if (userId && !permissions) {
          console.log('Fetching user permissions from API...');
          const response = await api.get(
            `?table=users&custom_specific=get_user_permission&from_id=${userId}`
          );
          const permissionsData = response.data.map(p => p.name);
          setPermissions(permissionsData);
          console.log('Permissions fetched:', permissionsData);
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    if (userId && !permissions) {
      fetchPermissions();
    }
  }, [userId, permissions]);

  // Effect to handle token expiration
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired()) {
        handleLogout();
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  return { 
    user, 
    authLoading, 
    authOkay, 
    handleLogout, 
    userId, 
    permissions,
    userEmail // Add this to the return object if needed
  };
}

export default useAuth;
