import React, { useState, useContext } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import FormatPaintIcon from '@mui/icons-material/FormatPaint'; // Import the paint icon
import { ThemeContext } from '../../contexts/ThemeContext';
import { updateItem } from "../../api";
import { useMutation } from "@tanstack/react-query";

const StylePicker = () => {
  const { currentTheme, setCurrentTheme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null); // Manage menu state
  const userId = localStorage.getItem('userId');

  const open = Boolean(anchorEl);

  // Open the menu
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle theme selection and database update
  const handleThemeChange = (theme) => {
    setCurrentTheme(theme); // Update theme in context
    setAnchorEl(null); // Close the menu
    console.log("Selected Theme:", theme);
    updateTheme.mutate({ userId, theme }); // Pass userId and theme to the mutation
  };

  // Mutation for updating the theme in the database
  const updateTheme = useMutation({
    mutationFn: ({ userId, theme }) => updateItem("users", userId, "theme", theme),
    onSuccess: (data) => {
      console.log("Theme successfully updated:", data);
      localStorage.setItem('StoredUserDataFromDb.theme', currentTheme);
      console.log("StoredUserDataFromDb : ", localStorage.getItem('StoredUserDataFromDb.theme'));
    },
    onError: (error) => {
      console.error("Error updating theme:", error);
    },
  });

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{ color: 'inherit' }}
        aria-controls={open ? 'style-picker-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
      >
        <FormatPaintIcon />
      </IconButton>
      <Menu
        id="style-picker-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleThemeChange('blue')}>Blue</MenuItem>
        <MenuItem onClick={() => handleThemeChange('dark_blue')}>Dark Blue</MenuItem>
        <MenuItem onClick={() => handleThemeChange('asse_green')}>Asse Green</MenuItem>
        <MenuItem onClick={() => handleThemeChange('strong_green')}>Strong Green</MenuItem>
        <MenuItem onClick={() => handleThemeChange('purple')}>Purple</MenuItem>
      </Menu>
    </>
  );
};

export default StylePicker;
