import React, { createContext, useContext, useState } from "react";

const CacheContext = createContext({
  noCache: false,
  toggleCache: () => {},
});

export const CacheProvider = ({ children }) => {
  const [noCache, setNoCache] = useState(false);
  const toggleCache = () => setNoCache((prev) => !prev);

  return (
    <CacheContext.Provider value={{ noCache, toggleCache }}>
      {children}
    </CacheContext.Provider>
  );
};

export const useCache = () => useContext(CacheContext);
