import React from 'react';
import { signInWithRedirect } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import awsmobile from '../../aws-exports';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

Amplify.configure(awsmobile);

function Login() {  // Remove props since they're not needed
  const handleLogin = async () => {
    try {
      await signInWithRedirect({
        provider: 'Google'  // provider name should be capitalized
      });
    } catch (error) {
      //console.error('Error during login:', error);
    }
  };


  const storedMessage = localStorage.getItem('authMessage');

  return (
    <Box
      display="flex"
      flexDirection="column" // Aligner les éléments verticalement
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box mb={2} fullWidth>
        <Button variant="contained" onClick={handleLogin}>
          Sign in with Google
        </Button>
      </Box>
  
      {storedMessage!="null" && storedMessage!="" && (
        <Box mt={2} color="error.main" textAlign="center"> {/* Ajout d'un espacement vertical (mt) et centrage */}
          {storedMessage}
        </Box>
      )}
    </Box>
  );
}

export default Login;