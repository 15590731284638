import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { updateItem } from '../../api'; // Assuming `updateItem` is your API function
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Moon icon
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Sun icon

const DarkModeToggle = () => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const userId = localStorage.getItem('userId'); // Get the user ID

  // Mutation for updating dark mode preference
  const updateDarkMode = useMutation({
    mutationFn: (newMode) => updateItem('users', userId, 'theme_mode', newMode),
    onSuccess: (data) => {
      console.log('Dark mode successfully updated:', data);
    },
    onError: (error) => {
      console.error('Error updating dark mode:', error);
    },
  });

  const handleToggle = () => {
    const newMode = !darkMode;
    setDarkMode(newMode); // Update in ThemeContext
    localStorage.setItem('StoredUserDataFromDb.theme_mode', newMode ? 'dark' : 'light'); // Persist locally
    updateDarkMode.mutate(newMode ? 'dark' : 'light'); // Persist to database
    console.log("StoredUserDataFromDb : ", localStorage.getItem('StoredUserDataFromDb.theme_mode'));
  };

  return (
    <Tooltip title={darkMode ? "Activate Light Mode" : "Activate Dark Mode"}>
      <IconButton
        onClick={handleToggle}
        sx={{ color: 'inherit' }}
      >
        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  );
};

export default DarkModeToggle;
