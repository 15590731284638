import { useState, useEffect } from "react";
import * as Constants from "../constants/selectConstants"; // Adjust the path as needed
import { useCache } from "../contexts/CacheContext"; 

export const useSelectOptions = (fields) => {
  const [selectMappings, setSelectMappings] = useState({});
  const { noCache } = useCache(); // Get the cache state

  useEffect(() => {
    if (!fields || fields.length === 0) {
      // Handle the case where fields are not yet available
      return;
    }

    fields.forEach((field) => {
      if (
        field.fieldType === "select" ||
        field.fieldType === "autocomplete" ||
        field.fieldType === "autocompleteWithQuery"
      ) {
        if (field.selectLocalValue && Constants[field.selectLocalValue]) {
          // Local constants
          setSelectMappings((prev) => ({
            ...prev,
            [field.key]: Constants[field.selectLocalValue].reduce(
              (acc, curr) => {
                acc[curr.id] = curr.n ? curr.n : curr.name;
                return acc;
              },
              {}
            ),
          }));
        } else if (field.selectFile) {
          let filePath;
          if (field.fieldType === "autocompleteWithQuery") {
            filePath = `dynamic_json/${field.selectFile}.json`;
          } else {
            filePath = field.selectFile;
          }

          // Add a cache-busting query parameter if noCache is true
          const cacheBuster = noCache ? `?${Date.now()}` : '';
          const s3Url = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${filePath}${cacheBuster}`;

          fetch(s3Url)
            .then((response) => {
              if (!response.ok) throw new Error("Network response was not ok.");
              return response.json();
            })
            .then((data) => {
              setSelectMappings((prev) => ({
                ...prev,
                [field.key]: data.reduce((acc, curr) => {
                  acc[curr.id] = curr.n ? curr.n : curr.name;
                  return acc;
                }, {}),
              }));
            })
            .catch((error) =>
              console.error("Failed to load select options from S3", error)
            );
        }
      }
    });
  }, [fields, noCache]);

  return selectMappings;
};
