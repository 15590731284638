import React, {useState, useMemo} from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { getDisplayValue, getDisplayValueWithFullModelData } from "../../../utils/utils";
import { render } from '@testing-library/react';
import useModelsLoader from '../../../hooks/useModelsLoader';

// Define styles
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    margin: '10px 15px 0 15px',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    backgroundColor: '#e0e0e0',
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  header: {
    letterSpacing: "3px",
    fontFamily: "Museo100",
    fontSize: 14,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  pageHeader: {
    fontFamily: "Museo100",
    marginBottom: 10,
    fontSize: 14,
    textAlign: "center",
  },
  totalsRow: {
    flexDirection: 'row',
    paddingVertical: 4,
    borderTopWidth: 1,
    borderTopColor: '#000',
    fontWeight: 'bold',
  },
  totalLabelCell: {
    fontSize: 10,
    fontWeight: 'bold',
    padding: 5,
  },
  totalCell: {
    fontSize: 10,
    padding: 5,
    textAlign: 'right',
  },
});


  // Helper function to chunk data into pages
  const chunkData = (data, rowsPerPage) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += rowsPerPage) {
      chunks.push(data.slice(i, i + rowsPerPage));
    }
    return chunks;
  };
  
  // Function to calculate totals
  const calculateTotals = (dataArray, models, fieldsToDisplay) => {
    const totals = {};
    fieldsToDisplay.forEach((fieldKey) => {
      const [tableName, fieldName] = fieldKey.split('.');
      const model = models[tableName];
      const field = model?.fields.find((f) => f.key === fieldName);
      if (field && field.pdfOptionsTotal) {
        totals[fieldKey] = dataArray.reduce((sum, item) => {
          const value = parseFloat(item[fieldKey]);
          return sum + (isNaN(value) ? 0 : value);
        }, 0);
      }
    });
    return totals;
  };


function ReportPDF({ checkedFields, data, models, configOptions }) {
  const dataArray = Array.isArray(data) ? data : [data];


  const fieldsToDisplay = useMemo(
    () => Object.keys(checkedFields).filter((key) => checkedFields[key]),
    [checkedFields]
  );

  const totals = useMemo(
    () => calculateTotals(dataArray, models, fieldsToDisplay),
    [dataArray, models, fieldsToDisplay]
  );

/*
const { modelData: modelDataMultiple} = useModelsLoader(["artworks","sales"]);
console.log("modelDataMultiple", modelDataMultiple);

/*
const { modelData: modelDataArtworks } = useModelsLoader(["artworks",]);
console.log("modelDataArtworks", modelDataArtworks);
const selectMappingsArtworks = useSelectOptions(modelDataArtworks?.fields);

const { modelData: modelDataSales } = useModelLoader("sales");
console.log("modelDataSales", modelDataSales);
const selectMappingsSales = useSelectOptions(modelDataSales?.fields);

console.log("selectMappingsArtworks", selectMappingsArtworks);

console.log("selectMappingsSales", selectMappingsSales);


const combinedSelectMappings = {
  ...selectMappingsArtworks,
  ...selectMappingsSales,
};


const { modelData: modelDataMultiple} = useModelsLoader(["artworks", "sales"]);
console.log("modelDataMultiple", modelDataMultiple);
//const selectMappingsArtworks = useSelectOptions(modelDataMultiple?.artworks.fields);

*/



const { modelData: modelDataMultiple} = useModelsLoader(configOptions.tables);

// Use useMemo to compute modelDataCombined only when modelDataMultiple or configOptions.table changes
const modelDataCombined = useMemo(() => {
  const combined = [];
  configOptions.tables.forEach((table) => {
    const data = modelDataMultiple[table]?.fields; // Access the fields array
    if (Array.isArray(data)) {
      data.forEach((item) => {
        // Check if an object with the same key already exists
        if (!combined.some((existing) => existing.key === item.key)) {
          combined.push(item); // Add unique objects
        }
      });
    } else {
      console.warn(`No valid fields array for table: ${table}`);
    }
  });
  return combined;
}, [modelDataMultiple, configOptions.tables]);



const selectMappingsCombined = useSelectOptions(modelDataCombined);


const headers = useMemo(() => {
  return fieldsToDisplay.map((fieldKey) => {
    const [tableName, fieldName] = fieldKey.split('.');
    const model = models[tableName];
    const field = model?.fields.find((f) => f.key === fieldName);
    return `${tableName}.${field?.title || fieldName}`;
  });
}, [fieldsToDisplay, models]);


const pages = useMemo(() => chunkData(dataArray, configOptions.rowsPerPage), [dataArray, configOptions.rowsPerPage]);

  
  return (
    <Document>
      {pages.map((pageData, pageIndex) => (
        <Page key={pageIndex} size="A4">
          {/* Header */}
          <Text style={styles.header}>Ceysson & Bénétière</Text>
          {configOptions.showTitleAndPage && <Text style={styles.pageHeader}>{configOptions.title} - Page {pageIndex + 1}</Text>}

          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              {configOptions.showImage && (
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Image</Text>
                </View>
              )}
              {headers.map((headerTitle, index) => (
                <View key={index} style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>{headerTitle}</Text>
                </View>
              ))}
            </View>

            {/* Table Rows */}
            {pageData.map((rowData, rowIndex) => (
              <View key={rowIndex} style={[
                styles.tableRow,
                { minHeight: configOptions.rowHeight }, // Apply dynamic row height
              ]}>
                {configOptions.showImage && (
                  <View style={styles.tableCol}>
                    <Image
                      src={`${process.env.REACT_APP_S3_BASE_IMG}${configOptions.imageTable}/${rowData[`${configOptions.imageTable}.id`]}/main_image/${rowData[`${configOptions.imageTable}.main_image`]}-${configOptions.imageQuality}.jpg`}
                      style={{ maxWidth: 450*configOptions.imageSize/100, maxHeight: 300*configOptions.imageSize/100, objectFit: "contain" }}
                    />
                  </View>
                )}
                {fieldsToDisplay.map((fieldKey, idx) => {
                  const [tableName, fieldName] = fieldKey.split('.');
                  const model = models[tableName];
                  const field = model?.fields.find((f) => f.key === fieldName);

                  return (
                    <View key={idx} style={styles.tableCol}>
<Text style={styles.tableCell}>
  {rowData[fieldKey] !== null && rowData[fieldKey] !== undefined
    ? getDisplayValueWithFullModelData(models[tableName], fieldName, rowData[fieldKey], selectMappingsCombined)
    : '' }
</Text>
                    </View>
                  );
                })}
              </View>
            ))}

            {/* Totals Row - Only on Last Page */}
            {pageIndex === pages.length - 1 && (
              <View style={styles.totalsRow}>
                {configOptions.showImage && (
                  <View style={styles.tableCol}>
                    <Text style={styles.totalLabelCell}>Total</Text>
                  </View>
                )}
                {fieldsToDisplay.map((fieldKey, idx) => (
                  <View key={idx} style={styles.tableCol}>
                    <Text style={styles.totalCell}>
                      {totals[fieldKey] !== undefined ? totals[fieldKey].toString() : ''}
                    </Text>
                  </View>
                ))}
              </View>
            )}
          </View>
        </Page>
      ))}
    </Document>
  );
}

export default ReportPDF;
