import React, { useState, useEffect } from "react";
import {
  Typography,
  Modal,
  Box,
  Button,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from "@mui/material";
import { postItems, updateManyItems, updateItem, updateManyItemsSameValue } from "../../api";
import { Link } from "react-router-dom";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutocompleteField from "../Autocomplete/AutocompleteField";
import AutocompleteWithQuery from "../Autocomplete/AutocompleteWithQuery";
import dayjs from "dayjs";
import * as Constants from "../../constants/selectConstants";
import useModelLoader from "../../hooks/useModelLoader";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"; // Import your API functions

const AddAnythingMtmModal = ({
  section,
  selectedRowIds,
  handleClose,
  moreOptions,
  target,
  isLarge,
  manyOrFk,
  prefixWithTargetName,
  reverse
}) => {
  const [selectedSet, setSelectedSet] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isMtm, setIsMtm] = useState(true);
  const [isBulkFk, setIsBulkFk] = useState(true);
  const [insertId, setInsertId] = useState(null);
  const [formData, setFormData] = useState({});
  const [fieldsData, setFieldsData] = useState([]);
  const [conditionnalLoadValue, setConditionnalLoadValue] = useState([]);
  const [selectOptions, setSelectOptions] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  let targetTable = target;

  if (target == "sets") {
    targetTable = "sets_of_" + section;
  }

  //if (target == "narratives") {
    if (prefixWithTargetName == true) { 
    targetTable = targetTable+"_"+ section;
  } 

  const { modelData, filteredFields } = useModelLoader(targetTable);
  const junctionTable = reverse ? `${section}_${targetTable}` : `${targetTable}_${section}`;

  const fromTable = section;
  const fromTableKey = fromTable + "_id";
  const targetTableKey = targetTable + "_id";

  useEffect(() => {
if(manyOrFk=="fk"){
      setFormData({ [fromTableKey]: selectedRowIds });
      setIsMtm(false);

      if (moreOptions.bulkSame){
        setIsBulkFk(true);
      }
    }
    else if(manyOrFk=="fk_self"){
      setIsMtm(false);
    }
    console.log("selectedRowIds error", selectedRowIds)
  }, [target, section]);


  const updateMutationJunction = useMutation({
    mutationFn: (newData) =>
      updateManyItems(junctionTable, [targetTableKey, fromTableKey], newData),
    onSuccess: () => {
      setShowSuccess(true);
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });



  const updateMutationTarget = useMutation({
    mutationFn: (data) =>
    updateManyItemsSameValue(section,{[targetTableKey]: data.NewInsertID}, selectedRowIds),
    onSuccess: () => {
      // Handle success after updating junction table
      setShowSuccess(true);
      console.log("success", showSuccess);
    },
    onError: (err) => {
      console.error("Error updating junction table:", err);
    },
  });


  const postMutationSelf = useMutation({
    mutationFn: (data) => updateItem(fromTable, selectedRowIds, targetTable+"_id", data.NewInsertID),
    onSuccess: (data) => {
      setShowSuccess(true);
    },
    onError: (error) => {
      console.error("Error posting data:", error);
    },
  });



  const addHistory = useMutation({
    mutationFn: (data) =>
      postItems(`history_${targetTable}`, data, true, true),
    onSuccess: () => {
      console.log("History successfully recorded");
    },
    onError: (error) => {
      console.error("Error recording history:", error);
    },
  });
  

  const addHistorySource = useMutation({
    mutationFn: (data) =>
      postItems(`history_${fromTable}`, data, true, true),
    onSuccess: () => {
      console.log("History Same Value successfully recorded");
    },
    onError: (error) => {
      console.error("Error recording hsitory updateManyItemsSameValue:", error);
    },
  });
  



  const postMutation = useMutation({
    mutationFn: (data) => postItems(targetTable, data),
    onSuccess: (data) => {
      const logEntry = JSON.parse(data.body);
      const insertID = logEntry.insertID;
      setInsertId(insertID);
      console.log(insertID);

      // After successfully posting the new item, update the junction table
      if (isMtm) {
        if (isMtm) {
          updateMutationJunction.mutate(
            selectedRowIds.map((id) => ({
              [targetTableKey]: insertID,
              [fromTableKey]: id,
            }))
          );
        } 
      } 
      else if(isBulkFk){
        updateMutationTarget.mutate({
          NewInsertID: insertID
        }

        );
      }
      else if(manyOrFk=="fk_self"){
        postMutationSelf.mutate({
          NewInsertID: insertID
        });
      }else {
        setShowSuccess(true);
      }

        // Handle truncation logic
    const totalIds = selectedRowIds.length;
    let isTruncated = false;

    // Always display the first 5 IDs
    let displayedIds = selectedRowIds.slice(0, 6).join(", ");
    const remainingCount = totalIds - 6;

    if (totalIds > 5) {
      displayedIds += ` and ${remainingCount} more`;
      isTruncated = true;
    }


    const historyTargetData = {
      field_name: fromTable,
      [`${targetTable}_id`]: insertID,
      old_value: `added ${totalIds} ${fromTable} upon creation`,
      new_value: displayedIds,
      event_type:2,
    };

    // Include `other_info` only if truncation occurred
    if (isTruncated) {
      historyTargetData.other_info = JSON.stringify({
          number: totalIds,
          ids: selectedRowIds
      });
  }
  

    addHistory.mutate(historyTargetData);




    const allValues = selectedRowIds.map((selectedRowId) => ({
      field_name: targetTable,
      [`${fromTable}_id`]: selectedRowId,
      old_value: `added to ${targetTable}`,
      new_value: `${formData.name} (${insertID})`,
      other_info: JSON.stringify({
        id: insertID,
      }),
    }));
    
    console.log("allValues", allValues);
    addHistorySource.mutate(allValues); 
    



    },
    onError: (error) => {
      console.error("Error posting data:", error);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    postMutation.mutate(formData);
    console.log("formData", formData);  
  };

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };


  const handleEmailChange = (key, value) => {
    setFormData({ ...formData, [key]: value });

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})$/;


    if (value.match(validRegex)) {
    setDisableSubmit(false);
    setIsEmailValid(true);
}
else{
  setDisableSubmit(true);
  setIsEmailValid(false);
}
  };

  const handleInputDateChange = (key, value) => {
    if (value && value !='0000-00-00') {
          // Format the Day.js object to 'YYYY-MM-DD'
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          console.log("Formatted date:", formattedDate);
    
          setFormData({
            ...formData,
            [key]: formattedDate,
          });
          console.log(formData)
        }
      };

      
      const handleInputDateTimeChange = (key, value) => {
        if (value && value !='0000-00-00 00:00:00') {
              // Format the Day.js object to 'YYYY-MM-DD'
              const formattedDate = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
              console.log("Formatted date:", formattedDate);
              setFormData({
                ...formData,
                [key]: formattedDate,
              });
          };
      }




  useEffect(() => {
    if (filteredFields) {
      setFieldsData(filteredFields);
    }
  }, [filteredFields]);

  useEffect(() => {
    fieldsData.forEach((field) => {
      if (field.selectLocalValue) {
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          [field.key]: Constants[field.selectLocalValue],
        }));
      } else if (field.fieldType === "select" && field.selectFile) {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_GLOBAL_MAPPING_URL}${
                field.selectFile
              }?${Math.floor(Math.random() * 10000)}`
            );
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            console.log(data);
            setSelectOptions((prevOptions) => ({
              ...prevOptions,
              [field.key]: data,
            }));
          } catch (error) {
            console.error(
              "Failed to fetch select options for",
              field.key,
              error
            );
          }
        };

        fetchData();
      }
    });
  }, [fieldsData]);






  if (selectedRowIds.length < 1) {
    return (
      <div>
        <Typography variant="h6">
          Sorry, you have to select one item first.
        </Typography>
      </div>
    );
  }


  if (moreOptions.limitToOne && selectedRowIds.length > 1) {
    return (
      <div>
        <Typography variant="h6">
          Sorry, you can't add more than one records of {section} to {targetTable}.
        </Typography>
      </div>
    );
  } 

  return (
    <Box>
      {showSuccess ? (
        <div>
          <p>Update successful!</p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Button
              sx={{ m: 2, width: "45%" }}
              onClick={() => setShowSuccess(false)}
              variant="contained"
              color="primary"
            >
              Add to Another {targetTable}
            </Button>
            <Button
              sx={{ m: 2, width: "45%" }}
              variant="contained"
              color="secondary"
              component={Link}
              to={`/${targetTable}/detail/${insertId}`}
            >
              View {targetTable}
            </Button>
          </Box>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {fieldsData.map((field) => (
                <Grid item xs={isLarge ? 6 : 12} key={field.key}>
                  <div key={field.key}>
                    {(() => {
                      switch (field.fieldType) {
                        case "input":
                          return (
                            <TextField
                              sx={{width: "100%" }}
                              label={field.title}
                              variant="outlined"
                              type="text"
                              value={formData[field.key] || ""}
                              onChange={(e) =>
                                handleInputChange(field.key, e.target.value)
                              }
                              required={field.required}
                            />
                          );
                        case "select":
                          const options = selectOptions[field.key] || [];
                          return (
                            <FormControl fullWidth margin="normal">
                              <InputLabel>{field.title}</InputLabel>
                              <Select
                                label={field.title}
                                value={formData[field.key] || ""}
                                onChange={(e) =>
                                  handleInputChange(field.key, e.target.value)
                                }
                                required={field.required}
                              >
                                {options.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.n || option.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          );
                        case "autocomplete":
                          return (
                            <AutocompleteField
                              title={field.title}
                              selectFile={field.selectFile}
                              selectFileKey={field.selectFileKey}
                              selectFreesolo={field.selectFreesolo}
                              conditionnalFileLoad={field.conditionnalFileLoad}
                              formData={formData}
                              defaultLoadValue={field.defaultLoadValue}
                              onValueChange={(value) =>
                                handleInputChange(field.key, value)
                              }
                            />
                          );

                        case "autocompleteWithQuery":
                          return (
                            <AutocompleteWithQuery
                              title={field.title}
                              selectFile={field.selectFile}
                              selectFileKey={field.selectFileKey}
                              selectFreesolo={field.selectFreesolo}
                              conditionnalFileLoad={field.conditionnalFileLoad}
                              formData={formData}
                              onValueChange={(value) =>
                                handleInputChange(field.key, value)
                              }
                            />
                          );

                        case "datetime":
                          return (
                            <div>
                              <DateTimePicker
                              ampm={false}
                                sx={{width: "100%" }}
                                label={field.title}
                                value={dayjs(formData[field.key]) || dayjs()}
                                onChange={(newDate) =>
                                  handleInputDateTimeChange(field.key, newDate)
                                }
                                renderInput={(params) => (
                                  <TextField
                                  sx={{width: "100%" }}
                                    {...params}
                                    fullWidth
                                    margin="normal"

                                  />
                                )}
                              />
                              <br />
                            </div>
                          );

                        case "date":
                          return (
                            <DatePicker
                              sx={{width: "100%" }}
                              label={field.title}
                              value={dayjs(formData[field.key]) || dayjs()}
                              onChange={(newDate) =>
                                handleInputDateChange(field.key, newDate)
                              }
                              renderInput={(params) => (
                                <TextField
                                sx={{width: "100%" }}
                                  {...params}
                                  fullWidth
                                  margin="normal"
                                />
                              )}
                            />
                          );

                          case "email":
                            return (
                              <TextField
                               error={!isEmailValid}
                               helperText={!isEmailValid && formData[field.key] !== '' ? "Invalid email" : ""}
                                sx={{width: "100%" }}
                                label={field.title}
                                variant="outlined"
                                type="text"
                                value={formData[field.key] || ""}
                                onChange={(e) =>
                                  handleEmailChange(field.key, e.target.value)
                                }
                                required={field.required}
                              />
                            );

                      
                      }
                    })()}
                  </div>
                </Grid>
              ))}
              <Grid>
                <Button
                  type="submit"
                  disabled={updateMutationJunction.isLoading || disableSubmit}
                >
                  {updateMutationJunction.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
              {updateMutationJunction.isError && <p>Error submitting form</p>}
            </Grid>
          </form>
        </>
      )}
    </Box>
  );
};

export default AddAnythingMtmModal;
