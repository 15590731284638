import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  CircularProgress,
  Typography,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import { useCache } from "../../../contexts/CacheContext"; 



const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};




const StepTwoSale = ({ formData, handleInputChange }) => {
  const [entitiesOptions, setEntitiesOptions] = useState([]);
  const [taxTypeOptions, setTaxTypeOptions] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);

  const { noCache } = useCache(); // Get the cache state
  const cacheBuster = noCache ? `?${Date.now()}` : '';


  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const urls = [
            `${process.env.REACT_APP_GLOBAL_MAPPING_URL}tax_rates_types.json${cacheBuster}`,
            `${process.env.REACT_APP_GLOBAL_MAPPING_URL}tax_rates.json${cacheBuster}`
        ];
        const [taxTypeOptions, taxOptions] = await Promise.all(urls.map(fetchData));
        setTaxTypeOptions(taxTypeOptions);
        setTaxOptions(taxOptions);
      } catch (error) {
        console.error("Fetching error:", error);
      }
    };

    fetchAllData();
  }, []);




  return (
    <div> 
        {
  (formData.is_export === true) ? (

    <TextField
    sx={{ width: "100%", my: 1 }}
    key="sales_price_without_taxes"
    label="sales_price_without_taxes"
    value={formData.sales_price_without_taxes}
    variant="outlined"
    InputProps={{
      startAdornment: <InputAdornment position="start">{formData.currency}</InputAdornment>,
    }}
    margin="normal"
    onChange={(e) => handleInputChange("sales_price_without_taxes", e.target.value)}
  />

  ) : formData.entities_id === 5 ? (
    <div>
     
<TextField
    sx={{ width: "100%", my: 1 }}
    key="sales_price_without_taxes"
    label="sales_price_without_taxes"
    value={formData.sales_price_without_taxes}
    variant="outlined"
    InputProps={{
      startAdornment: <InputAdornment position="start">{formData.currency}</InputAdornment>,
    }}
    margin="normal"
    onChange={(e) => handleInputChange("sales_price_without_taxes", e.target.value)}
  />


<TextField
    sx={{ width: "100%", my: 1 }}
    key="price_with_tax"
    label="sales_price_with_taxes"
    value={formData.sales_price_with_taxes}
    variant="outlined"
    margin="normal"
    disabled={true}
    InputProps={{
      startAdornment: <InputAdornment position="start">{formData.currency}</InputAdornment>,
    }}
    onChange={(e) => handleInputChange("sales_price_with_taxes", e.target.value)}
  />
  </div>
  )  :  (formData.entities_id != 5) ?(
    <div>

<TextField
    sx={{ width: "100%", my: 1 }}
    key="price_with_tax"
    label="sales_price_with_taxes"
    value={formData.sales_price_with_taxes}
    variant="outlined"
    margin="normal"
    InputProps={{
      startAdornment: <InputAdornment position="start">{formData.currency}</InputAdornment>,
    }}
    onChange={(e) => handleInputChange("sales_price_with_taxes", e.target.value)}
  />
      
<TextField
    sx={{ width: "100%", my: 1 }}
    key="sales_price_without_taxes"
    label="sales_price_without_taxes"
    value={formData.sales_price_without_taxes}
    disabled={true}
    variant="outlined"
    InputProps={{
      startAdornment: <InputAdornment position="start">{formData.currency}</InputAdornment>,
    }}
    margin="normal"
    onChange={(e) => handleInputChange("sales_price_without_taxes", e.target.value)}
  />
  </div>
  ) :null
}


<FormControl fullWidth margin="normal" sx={{ my: 1 }}>
    <InputLabel id="status-label">Choose Tax Rate Type</InputLabel>
    <Select
      labelId="status-label"
      label="Choose Tax Rates Type"
      value={formData.tax_rate_type || ""}
      sx={{ width: "100%", my: 1 }}
      title="Choose Tax Rate Type"
      onChange={(e) => handleInputChange("tax_rate_type", e.target.value)}
    >
      {taxTypeOptions?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.n}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  <br />

  {
  (formData.is_export != true) ? (
    <div>
  <FormControl fullWidth margin="normal" sx={{ my: 1 }}>
    <InputLabel id="status-label">Choose Tax Rate</InputLabel>
    <Select
      labelId="status-label"
      label="Choose Tax Rate<"
      value={formData.tax_rate || ""}
      sx={{ width: "100%", my: 1 }}
      title="Choose Tax Rate"
     // disabled={formData.entities_id==1}
      onChange={(e) => handleInputChange("tax_rate", e.target.value)}
    >
      {taxOptions?.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.n}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  <br />


   <TextField
    sx={{ width: "100%", my: 1 }}
    key="tax_amount"
    label="tax_amount"
    value={formData.tax_amount}
    variant="outlined"
    InputProps={{
      startAdornment: <InputAdornment position="start">{formData.currency}</InputAdornment>,
    }}
    margin="normal"
    onChange={(e) => handleInputChange("tax_amount", e.target.value)}
  />
  
  </div>
  ):null}

{
  (formData.tax_rate_type === 2) ? (

    <TextField
    sx={{ width: "100%", my: 1 }}
    key="margin"
    label="margin"
    value={formData.margin}
    variant="outlined"
    InputProps={{
      startAdornment: <InputAdornment position="start">{formData.currency}</InputAdornment>,
    }}
    margin="normal"
    onChange={(e) => handleInputChange("margin", e.target.value)}
  />

  ) : null}



  

    </div>
  );
};

export default StepTwoSale;
