import React, { createContext, useState, useEffect, useMemo } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import getTheme from '../themes/theme';
import themes from '../themes/themesTable'; // Import predefined themes

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState('dark_blue'); // Default theme
  const [darkMode, setDarkMode] = useState(false); // Default to light mode

  // Function to update CSS variables in the root element
  const updateRootCSSVariables = (primaryColor, secondaryColor, darkMode) => {
    const root = document.documentElement;

    root.style.setProperty('--primary-color', primaryColor);
    root.style.setProperty('--secondary-color', secondaryColor);
    root.style.setProperty('--background-color', darkMode ? '#121212' : '#ffffff');
    root.style.setProperty('--text-color', darkMode ? '#ffffff' : '#000000');
  };

  // Hydrate state from localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem('StoredUserDataFromDb.theme') || 'dark_blue';
    const savedDarkMode = localStorage.getItem('StoredUserDataFromDb.theme_mode') === 'dark';

    setCurrentTheme(savedTheme);
    setDarkMode(savedDarkMode);
  }, []); // Run once on mount

  // Update the CSS variables and generate the theme whenever state changes
  const theme = useMemo(() => {
    const primaryColor = themes[currentTheme]?.primaryColor || themes['dark_blue'].primaryColor;
    const secondaryColor = themes[currentTheme]?.secondaryColor || themes['dark_blue'].secondaryColor;

    // Update root CSS variables
    updateRootCSSVariables(primaryColor, secondaryColor, darkMode);

    // Generate MUI theme
    return getTheme(primaryColor, secondaryColor, darkMode ? 'dark' : 'light');
  }, [currentTheme, darkMode]);

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        setCurrentTheme,
        darkMode,
        setDarkMode,
      }}
    >
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
