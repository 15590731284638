import React, { useState, useEffect, useMemo } from 'react';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import ReportPDF from '../DocumentRender/ReportPDF';
import { Button, FormControl, Checkbox, FormLabel, FormControlLabel, Radio, RadioGroup, Slider, TextField } from '@mui/material';
import axios from 'axios';
import { loadModel } from '../../../utils/loadModel';
import {unslugify} from '../../../utils/utils';

function MakeReportFormModal({ section, selectedRowIds, config, ...additionalProps }) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [showImage, setShowImage] = useState({ table: config.image, show: false, quality: "2000-1200" });
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [isTableView, setIsTableView] = useState(true);
  const [tableModels, setTableModels] = useState({});
  const [modelsLoading, setModelsLoading] = useState(true);
  const [configOptions, setConfigOptions] = useState({
    tables: config.tables,
    title: 'Report', // Default title
    rowsPerPage: 20, // Default rows per page
    rowHeight: 25, // Default row height
    showImage: false, // Default image visibility
    imageTable: config.image,
    imageQuality: '50-40', // Default image quality
    imageSize: 100,
    showTitleAndPage: false
  });

  // API instance
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
    headers: { 'Content-Type': 'application/json' },
  });

  useEffect(() => {
    const token = localStorage.getItem('CognitoJWTToken');
    if (token) {
      api.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  }, [api]);

  // Load all models
  useEffect(() => {
    const loadModels = async () => {
      setModelsLoading(true);
      const models = {};
      console.log('Starting to load models for tables:', config.table);
      await Promise.all(
        config.tables.map(async (table) => {
          try {
            const model = await loadModel(table);
            if (model) {
              models[table] = model.modelData;
              console.log(`Model loaded for table: ${table}`, model.modelData);
            } else {
              console.warn(`Model not loaded for table: ${table}`);
            }
          } catch (error) {
            console.error(`Error loading model for table ${table}:`, error);
          }
        })
      );
      setTableModels(models);
      setModelsLoading(false);
      console.log('Finished loading all models', models);
    };

    loadModels();
  }, [config.tables]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const idsString = selectedRowIds.join(',');
        const queryParams = {
          ids: idsString,
          custom_specific: config.apiEndpoint,
        };
        const queryString = new URLSearchParams(queryParams).toString();
        const response = await api.get(`?${queryString}`);
        console.log("queryString", queryString);
        const results = response.data;
        console.log("results", results);
        setData(results);
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedRowIds.length > 0) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [selectedRowIds, config.apiEndpoint]);

  // Initialize checkboxes for fields from all tables
  useEffect(() => {
    if (!modelsLoading) {
      const initialCheckboxValues = {};
      config.tables.forEach((table) => {
        const modelData = tableModels[table];
        console.log(`Initializing checkboxes for table: ${table}`, modelData);
        if (modelData && modelData?.fields) {
          modelData?.fields.forEach((field) => {
            if (field.pdfOptions) {
              initialCheckboxValues[`${table}.${field.key}`] = field.pdfOptions === 'default';
            }
          });
        } else {
          console.warn(`Model data or fields missing for table: ${table}`);
        }
      });
      setCheckboxValues(initialCheckboxValues);
    }
  }, [tableModels, config.tables, modelsLoading]);

  const handleCheckboxChange = (event) => {
    setCheckboxValues({
      ...checkboxValues,
      [event.target.name]: event.target.checked,
    });
  };


  const handleConfigChange = (key, value) => {
    setConfigOptions((prev) => ({ ...prev, [key]: value }));
  };


  const selectedRowCount = selectedRowIds?.length || 0;
  
  const selectedResultsCount = data?.length || 0;

  const documentMemo = useMemo(() => {
    if (!data || Object.keys(data).length === 0) return null;
    return (
<ReportPDF
  configOptions={configOptions}
  checkedFields={checkboxValues}
  section={section}
  data={data}
  isTableView={isTableView}
  models={tableModels}
  selectedTables={config.tables}
  showImage={showImage}
/>

    );
  }, [section, data, checkboxValues, isTableView, tableModels, config.tables]);

  if (modelsLoading || isLoading) {
    return <div>Loading...</div>;
  }

  
 // Ensure models are passed to ReportPDF
 return (
  <div style={{ display: 'flex', width: '100%' }}>
    {/* Left side: Form with checkboxes */}
    <div style={{ width: '50%' }}>
    {selectedRowCount} records in {section} selected<br/>
    {selectedResultsCount} records back from db
      <form>
      <Button style={{ marginRight: '10px', marginTop: '10px', float: 'right'}} variant="outlined" size="small" href={pdfBlobUrl} download={`${configOptions.title}.pdf`}>
              Download PDF
        </Button>
        <FormControl component="fieldset">
        <FormLabel>Title and name of your pdf</FormLabel>
        <TextField style={{ width: '80%'}} 
        id="Title" key="Title" label="Title" variant="outlined" 
        onBlur={(e) => {
          const value = e.target.value.trim();
          handleConfigChange('title', value || 'Report'); // Default to 'Report' if empty
        }}
        />
                <FormControlLabel
                key="showTitleAndPage"
                control={
                  <Checkbox
                    checked={configOptions.showTitleAndPage || false}
                    onChange={(e) => handleConfigChange('showTitleAndPage', e.target.checked)}
                    name="showTitleAndPage"
                  />

                }
                
                label="Show Title and Page on every page"
              />
        <FormLabel>Image</FormLabel>
        <FormControlLabel
                key="showImage"
                control={
                  <Checkbox
                    checked={configOptions.showImage || false}
                    onChange={(e) => handleConfigChange('showImage', e.target.checked)}
                    name="showImage"
                  />

                }
                
                label="Show Image"
              />
          {configOptions.showImage && (
            <div>
              <FormLabel>Image Quality</FormLabel>
              <RadioGroup
                value={configOptions.imageQuality}
                onChange={(e) => handleConfigChange('imageQuality', e.target.value)}
                row
              >
                <FormControlLabel
                  value="900-600"
                  control={<Radio />}
                  label="High Quality"
                />
                <FormControlLabel
                  value="450-300"
                  control={<Radio />}
                  label="Medium Quality"
                />
                <FormControlLabel
                  value="132-80"
                  control={<Radio />}
                  label="Low Quality"
                />
                                <FormControlLabel
                  value="50-40"
                  control={<Radio />}
                  label="Very Low Quality"
                />
              </RadioGroup>
            </div>
          )}
{configOptions.showImage && (
<>
<FormLabel>Image Size</FormLabel>
            <Slider
              aria-label="Image Size %"
              value={configOptions.imageSize}
              onChange={(e, value) =>
                setConfigOptions((prev) => ({ ...prev, imageSize: value }))
              }
              valueLabelDisplay="auto"
              step={1}
              min={10}
              max={100}
              marks
              style={{ marginBottom: '1rem', width:'80%' }}
            /> </>    
          )}


      <FormLabel>Rows Per Page</FormLabel>
          <Slider
            aria-label="Rows Per Page"
            value={configOptions.rowsPerPage}
            onChange={(e, value) =>
              setConfigOptions((prev) => ({ ...prev, rowsPerPage: value }))
            }
            valueLabelDisplay="auto"
            step={1}
            min={5}
            max={30}
            marks
            style={{ marginBottom: '1rem', width:'80%'  }}
          />


      <FormLabel>Rows Height</FormLabel>
          <Slider
            aria-label="Row Height"
            value={configOptions.rowHeight}
            onChange={(e, value) =>
              setConfigOptions((prev) => ({ ...prev, rowHeight: value }))
            }
            valueLabelDisplay="auto"
            step={1}
            min={10}
            max={80}
            marks
            style={{ marginBottom: '1rem', width:'80%' }}
          />




          {config.tables.map((table) => {
            const modelData = tableModels[table];
            if (!modelData || !modelData.fields) {
              return null;
            }
            return (
              <div key={table}> <br/>
                <FormLabel>{unslugify(table)}</FormLabel>
                <br/> <br/>
                {modelData.fields
                  .filter((field) => field.pdfOptions)
                  .map((field) => {
                    const fieldKey = `${table}.${field.key}`;
                    return (
                      <FormControlLabel
                        key={fieldKey}
                        control={
                          <Checkbox
                            checked={checkboxValues[fieldKey] || false}
                            onChange={handleCheckboxChange}
                            name={fieldKey}
                          />
                        }
                        label={field.title}
                      />
                    );
                  })}
              </div>
            );
          })}
        </FormControl>

        {/* PDF download link and BlobProvider */}
        <BlobProvider
          document={
            <ReportPDF
              showImage={showImage}
              configOptions={configOptions}
              checkedFields={checkboxValues}
              data={data}
              models={tableModels}
            />
          }
        >
          {({ url }) => (
            <> <br/>
              <Button style={{ marginBottom: '100px', marginRight: '20px', float: 'right'}} variant="outlined" size="small" href={url} download={`${configOptions.title}.pdf`}>
              Download PDF
        </Button>
              {(() => {
                if (url && url !== pdfBlobUrl) setPdfBlobUrl(url);
                return null;
              })()}
            </>
          )}
        </BlobProvider>
      </form>
    </div>

    {/* Right side: PDF preview */}
    <div style={{ width: '50%' }}>
      {pdfBlobUrl ? (
        <iframe
          src={pdfBlobUrl}
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="PDF Preview"
        />
      ) : (
        <p>No preview available.</p>
      )}
    </div>
  </div>
);
}

export default MakeReportFormModal;
