import React, { useMemo } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import useModelLoader from '../../../hooks/useModelLoader';
import JsBarcode from 'jsbarcode';
import dayjs from "dayjs";
import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { getDisplayValue, getDisplayValueWithFullModelData } from "../../../utils/utils";
import { formattedTodaysDate, unslugify, priceFormatting } from "../../../utils/utils";
import { Header, FooterWithPageNumber } from "./HeaderAndFooter";
import fonts from "../../../utils/fonts";


const styles = StyleSheet.create({
  page: {
    fontFamily: 'Museo100',
    fontSize: 7,
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 10,
    height: '100%', // Ensure the page takes full height
    justifyContent: 'flex-start'
  },
  gridContainer: {
    marginTop: 10,
    paddingLeft: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  barcodeContainer: (entriesPerPage) => ({
    height: entriesPerPage === 10 ? '20%' : entriesPerPage === "table" ? "50px" : '10%',
    width: '100%', // Barcode container takes full width of the section
    alignItems: 'center', // Center barcode horizontally
    marginBottom: 5, // Add some margin at the bottom of the barcode
}),
  textContainer: (entriesPerPage) => ({
    padding: 2,
    fontSize: 7,
    width: entriesPerPage === 10 ? '60%' : '100%',
    height: entriesPerPage === 10 ? '100%' : '50%',
    position: 'relative', // Ensure the container is relative
    justifyContent: 'center',
    alignItems: 'left',
    display: 'flex', // Use flex to center the image
}),
  imageContainer: (entriesPerPage) => ({
    width: entriesPerPage === 10 ? '40%' : '100%',
    height: entriesPerPage === 10 ? '100%' : entriesPerPage === 10 ? '65%' : '50%',
    position: 'relative', // Ensure the container is relative
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
   // border: '1px',
   // borderColor: 'black'
}),
  section: (entriesPerPage) => ({
    padding: 2,
    width: entriesPerPage === 1 ? '100%' : '275px', 
    height: entriesPerPage === 1 ? '720px' : entriesPerPage === 4 ? '345px' : '147px',
    flexGrow: 0, 
    flexShrink: 1, 
    marginBottom: 'auto',
    position: 'relative', 
    display: 'flex',
    flexDirection: entriesPerPage === 10 ? 'column' : 'column', 
   // border: '1px',
   // borderColor: 'blue',
    alignItems: 'flex-start',
  }),
  image: (entriesPerPage, size) => ({
    maxHeight: entriesPerPage === 1 ? `${(800 * size) / 100}px` : entriesPerPage === 4 ? `${(200 * size) / 100}px` : '70px',
    maxWidth: entriesPerPage === 1 ? `${(600 * size) / 100}px` : entriesPerPage === 4 ? `${(300 * size) / 100}px` : '120px',
    alignSelf: 'center',
  }),
  contentContainer: (entriesPerPage) => ({
    flexDirection: entriesPerPage === 10 ? 'row' : 'column', 
    width: '100%', // Content container takes full width
  }),
  footerContainer: {
    position: 'absolute', // Position the footer absolutely
    bottom: 0, // Stick to the bottom
    left: 0,
    right: 0,
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontFamily: 'Museo100',
    flexDirection: 'row',
    justifyContent: 'space-between', // This will space out your items
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 30,
  },
  footerItem: {
    fontFamily: 'Museo100',
    fontSize: 9,
    width: '33%',
    textAlign: 'center', // Center text in each section
  },
  footerLeft: {
    fontFamily: 'Museo100',
    textAlign: 'left', // Align text to the left for the left section
  },
  footerRight: {
    fontFamily: 'Museo100',
    textAlign: 'right', // Align text to the right for the right section
  },
  header: {
    letterSpacing:'3px',
    fontFamily: 'Museo100',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  footer: {
    fontFamily: 'Museo100',
    fontSize: 8,
    textAlign: 'center',
    margin: 10,
  },
  cbLogoSection: {
    width: '100%',
    height:'15px',
    fontFamily: 'Museo100',
    letterSpacing:'3px',
    fontSize: 9,
    marginTop: 5,
    textAlign: 'center',
  },
  generated: {
    width: '100%',
    height:'10px',
    fontFamily: 'Museo100',
    fontSize: 6,
    textAlign: 'center',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 4,
    marginBottom: 4,
  },
  tableHeaderCell: {
    flex: 1,
    fontSize: 8,
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 4,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
  },
  tableCell: {
    flex: 1,
    fontSize: 7,
  },  
  totalsRow: {
    flexDirection: 'row',
    paddingVertical: 4,
    borderTopWidth: 1,
    borderTopColor: '#000',
    fontWeight: 'bold',
  },
  totalLabelCell: {
    flex: 2, // Span label cell across multiple columns
    fontSize: 8,
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: 4, // Padding for label clarity
  },
  tableCell: {
    flex: 1,
    fontSize: 7,
    textAlign: 'center', // Align text to center
  },
  pageHeader: {
    fontFamily: "Museo100",
    marginBottom: 10,
    fontSize: 14,
    textAlign: "center",
  },
  barcodeValue: (entriesPerPage) => ({
    fontSize: entriesPerPage === 1 ? 14 : 6, 
    width: '100%',
    fontFamily: 'Museo300',
    textAlign: 'center',
  })
});


const calculateTotals = (data, modelData) => {
  const totals = {};
  modelData?.fields
    .filter(field => field.pdfOptionsTotal)
    .forEach(field => {
      totals[field.key] = data.reduce((sum, item) => {
        const value = parseFloat(item[field.key]);
        return sum + (isNaN(value) ? 0 : value);
      }, 0);
    });
  return totals;
};

const CustomGlobalEverythingPDF = ({ section="artworks", checkedFields={}, checkedCustomFields={}, entriesPerPage=10, data, isTableView=false, configOptions={}, ...props }) => {
  const { modelData } = useModelLoader(section);

  const selectMappings = useSelectOptions(modelData?.fields);
  const totals = useMemo(() => calculateTotals(data, modelData), [data, modelData]);

  const generateBarcode = (id, entriesPerPage) => {
    if (!id) {
      console.error('Invalid ID passed to generateBarcode:', id);
      return null;
    }

    try {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, id.toString().padStart(6, '0'), {
        format: "CODE128",
        width: 4,
        height: entriesPerPage === "table" ? 120 : 40,
        fontSize: 8,
        displayValue: false,
      });
      return canvas.toDataURL("image/png");
    } catch (error) {
      console.error('Error generating barcode:', error);
      return null;
    }
  };

  const renderCheckedFields = (item) => {
    return modelData?.fields
      .filter(field => checkedFields[field.key])
      .map(field => {
        const displayValue = getDisplayValueWithFullModelData(modelData, field.key, item[field.key], selectMappings);

        return (
          <Text style={styles.text} key={field.key}>
            {unslugify(field.title)}: {displayValue}
          </Text>
        );
      });
  };

  const chunkData = (data, size) => {
    const result = [];
    for (let i = 0; i < data.length; i += size) {
      result.push(data.slice(i, i + size));
    }
    return result;
  };

  const getTotalPages = (data, entriesPerPage) => {
    return Math.ceil(data.length / entriesPerPage);
  };

  const totalPages = getTotalPages(data, entriesPerPage);
  const groupedData = chunkData(data, entriesPerPage);
  const formattedDate = formattedTodaysDate();

  const hyphenTitle = configOptions.title!="" ? "-" : ""

  if (!selectMappings || !modelData) {
    return null; // Or a loading state if data is still being fetched
  }

  if (!data || !Array.isArray(data) || data.length === 0) {
    console.error('Invalid or empty data:', data);
    return <Text>No data to display</Text>;
  }

  return (
    <Document>
      {groupedData.map((group, pageIndex) => (
        <Page key={pageIndex} style={styles.page}>
          <Header />
          {configOptions.showTitleAndPage && <Text style={styles.pageHeader}>{configOptions.title} {hyphenTitle} Page {pageIndex + 1}</Text>}

          {isTableView ? (
            <View style={styles.tableContainer}>
              <View style={styles.tableHeader}>
                {configOptions.showImage && (
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Image</Text>
                  </View>
                )}
                {checkedCustomFields["barcode"] && (
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Barcode</Text>
                  </View>
                )}
                {modelData?.fields
                  .filter((field) => checkedFields[field.key])
                  .map((field) => (
                    <Text key={field.key} style={styles.tableHeaderCell}>
                      {field.title}
                    </Text>
                  ))}
              </View>

              {/* Render Table Rows */}
              {group.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  {configOptions.showImage && (
                    <View style={styles.tableCol}>
                      {item.main_image &&
                      <Image
                        style={{
                          maxWidth: (450 * configOptions.imageSize) / 100,
                          maxHeight: (300 * configOptions.imageSize) / 100,
                          objectFit: "contain",
                        }}
                        src={`${process.env.REACT_APP_S3_BASE_IMG}${section}/${item.id}/main_image/${item.main_image}-${configOptions.imageQuality}.jpg`}
                      />}
                    </View>
                  )}
                  {checkedCustomFields["barcode"] && (
                    <View style={{ marginLeft: "20px", height: "100px", width: "100px" }}>
                      <Image src={generateBarcode(modelData?.pdfConfig.barcodePrefix + '-' + item.id, "table")} />
                      <Text style={styles.barcodeValue("table")}>
                        {modelData?.pdfConfig.barcodePrefix}-{item.id.toString().padStart(6, '0')}
                      </Text>
                    </View>
                  )}
                  {modelData?.fields
                    .filter((field) => checkedFields[field.key])
                    .map((field) => (
                      <Text key={field.key} style={styles.tableCell}>
                        {getDisplayValueWithFullModelData(modelData, field.key, item[field.key], selectMappings)}
                      </Text>
                    ))}
                </View>
              ))}

              {/* Totals Row */}
              <View style={styles.totalsRow}>
                <Text style={styles.totalLabelCell}>Total</Text>
                {modelData?.fields
                  .filter((field) => checkedFields[field.key])
                  .map((field) => (
                    <Text key={field.key} style={styles.tableCell}>
                      {field.pdfOptionsTotal ? totals[field.key] : ''}
                    </Text>
                  ))}
              </View>
            </View>
          ) : (
            <View style={styles.gridContainer}>
              {group.map((item, itemIndex) => (
                <View key={itemIndex} style={styles.section(entriesPerPage)}>
                  {checkedCustomFields["barcode"] && (
                    <>
                      <View style={styles.barcodeContainer(entriesPerPage)}>
                        <Image src={generateBarcode(modelData?.pdfConfig.barcodePrefix + '-' + item.id, entriesPerPage)} />
                        <Text style={styles.barcodeValue(entriesPerPage)}>
                          {modelData?.pdfConfig.barcodePrefix}-{item.id.toString().padStart(6, '0')}
                        </Text>
                      </View>
                      <View style={styles.generated}>
                        <Text>Generated on {formattedDate}</Text>
                      </View>
                    </>
                  )}
                  <View style={styles.contentContainer(entriesPerPage)}>
                    <View style={styles.imageContainer(entriesPerPage)}>
                     {configOptions.showImage && (item.main_image!="" || item.main_image!=null)&& <Image style={styles.image(entriesPerPage, configOptions.imageSize)} src={`${process.env.REACT_APP_S3_BASE_IMG}${section}/${item.id}/main_image/${item.main_image}-${configOptions.imageQuality}.jpg`} />}
                    </View>
                    <View style={styles.textContainer(entriesPerPage)}>
                      {renderCheckedFields(item)}
                    </View>
                  </View>
                  {checkedCustomFields["cblogo"] && entriesPerPage > 1 && (
                    <View style={styles.cbLogoSection}>
                      <Text>Ceysson & Bénétière</Text>
                    </View>
                  )}
                </View>
              ))}
            </View>
          )}
          <FooterWithPageNumber pageIndex={pageIndex} totalPages={totalPages} />
        </Page>
      ))}
    </Document>
  );
};

export default CustomGlobalEverythingPDF;
